import { useRouter } from "next/router";
import { Modal, TextContainer } from "@shopify/polaris";

import React, { useEffect, useState, useCallback } from "react";
import { fetchPopupData } from "../utils/queries";
import { useAxios } from "../hooks/useAxios";
import useStore from "../providers/storeProvider";

function PopUp({ popupId, popupData, popupTriggeredTime, ...props }) {
  const router = useRouter();
  const [axios] = useAxios();
  const {storeData} = useStore();
  const [loading, setloading] = useState(true);
  const [active, setActive] = useState(true);
  const [header, setheader] = useState("");
  const [body, setbody] = useState("");
  const [primaryCtaText, setprimaryCtaText] = useState("");
  const [primaryCtaLink, setprimaryCtaLink] = useState("");
  const [placeholderData, setplaceholderData] = useState({});
  const [selectedPopupId, setselectedPopupId] = useState(null);
  const storefrontWidgetVersion = storeData?.storefrontWidgetVersion ?? 1;

  //condition
  const [noSubscription, setnoSubscription] = useState(false);

  const handleReset = () => {
    setheader("");
    setbody("");
    setprimaryCtaText("");
    setprimaryCtaLink("");
    setnoSubscription(false);
  };

  const replacePlacehoder = (primaryCtaLink, data) => {
    let link = primaryCtaLink;
    if (data && primaryCtaLink) {
      if (data.myshopify_domain) {
        link = link.replace("{{myshopify_domain}}", data.myshopify_domain);
      }
      if (data.product_title) {
        link = link.replace("{{product_title}}", data.product_title);
      }

      if (data.sellingPlanGroup_shopify_id) {
        link = link.replace(
          "{{sellingPlanGroup_shopify_id}}",
          data.sellingPlanGroup_shopify_id
        );
      }

      if (data.variant_id) {
        link = link.replace("{{variant_id}}", data.variant_id);
      }

      return link;
    }
    return link;
  };

  const handleClick = () => {
    let link = replacePlacehoder(primaryCtaLink, placeholderData);
    if (link && link.startsWith("https://")) {
      window.open(link, "_blank");
    } else {
      router.push(link ? link : "");
    }

    if (![3, 4, 8].includes(selectedPopupId)) {
      handleChange();
      handleReset();
    }
  };

  useEffect(() => {
    (async () => {
      if (popupId) {
        setloading(true);
        handleReset();
        let response = await fetchPopupData(axios, {
          ...popupData,
          id: popupId,
        });

        if (response.data) {
          setselectedPopupId(response.data.id);
          setheader(response.data.header);
          setbody(response.data.body);
          setprimaryCtaText(response.data.primary_cta_text);
          const link = storefrontWidgetVersion === 2 ? '/widgets/storefront/library' : response.data.primary_cta_link;
          setprimaryCtaLink(link);
          setloading(false);
        }
        setplaceholderData(response?.placeholderData || {});
        if (!response.display) {
          props.handlePopupTrigger({});
        }
      }
    })();

    if (!popupId) {
      setActive(false);
    } else {
      setActive(true);
    }
  }, [popupId]);
  const handleChange = useCallback(() => setActive(!active), [active]);

  useEffect(() => {
    if (!active && props) {
      props.handlePopupTrigger({});
    }
  }, [active]);

  if (loading) {
    return <></>;
  }
  if (popupId) {
    return (
      <Modal
        open={active}
        onClose={handleChange}
        title={header}
        primaryAction={{
          content: primaryCtaText,
          onAction: handleClick,
        }}
        secondaryActions={[]}
      >
        <Modal.Section>
          <TextContainer>
            <div dangerouslySetInnerHTML={{ __html: body }}></div>
          </TextContainer>
        </Modal.Section>
      </Modal>
    );
  } else {
    return <></>;
  }
}

export default PopUp;
