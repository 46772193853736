import Bugsnag from "@bugsnag/js";
import _ from "lodash";

import { CURRENCY_SYMBOLS } from "./constants";


export function truncate(str, n) {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
}

export function getOrdinal(str) {
    if (str == "11" || str == "12" || str == "13") {
        return str + "th";
    }
    let _last = str[str.length - 1];
    switch (_last) {
        case "1":
            return str + "st";
        case "2":
            return str + "nd";
        case "3":
            return str + "rd";
        default:
            return str + "th";
    }
}

export function scrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
}

export function isValidEmail(email) {
    let regex = new RegExp(
        "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])",
    );
    return !!email && typeof email === "string" && regex.test(email);
}

export function getShopifyNumericID(gid_string) {
    let _arr = gid_string.split("/");
    return _arr[_arr.length - 1];
}

export function getCurrencyDisplay(code) {
    if (code in CURRENCY_SYMBOLS) {
        return CURRENCY_SYMBOLS[code];
    } else {
        return code;
    }
}

export function getDateInStoreTimeZone({ date, timeZone }) {
    let formatedDate = new Date(date).toLocaleString("en-US", { timeZone });
    return formatedDate;
}

export const numberFormatter = (number, isMoney) => {
    if (number) {
        const parts = number.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0;
        if (parts && parts[1] && isMoney) {
            parts[1] = `${parts[1][0] || 0}${parts[1][1] || 0}`;
        } else if (isMoney) {
            parts[1] = "00";
        }
        return parts.join(".");
    } else {
        if (isMoney) {
            return "0.00";
        } else {
            return 0;
        }
    }
};

export function capitalize(str) {
    const lower = str.toLowerCase();
    return str.charAt(0).toUpperCase() + lower.slice(1);
}

export function generateSellingPlanName(intervalCount, interval, chargeEvery) {
    let _name = "";
    if (chargeEvery == 1) {
        _name = `Deliver every ${
            intervalCount > 1
                ? `${intervalCount} ${interval.toLowerCase()}s`
                : interval.toLowerCase()
        }`;
    } else {
        let _payEvery = chargeEvery * intervalCount;
        _name = `Pay every ${
            _payEvery > 1
                ? `${_payEvery} ${interval.toLowerCase()}s`
                : interval.toLowerCase()
        }, Deliver every ${
            intervalCount > 1
                ? `${intervalCount} ${interval.toLowerCase()}s`
                : interval.toLowerCase()
        }`;
    }
    return _name;
}

export function getIdFromGraphqlId(graphql_id) {
    const index = graphql_id.lastIndexOf("/");
    return graphql_id.substring(index + 1);
}

export function handleAdminError(
    error,
    shop,
    sourceFunction,
    sourceFile,
    id = "",
    data,
) {
    Bugsnag.notify(
        `Error occurred for '${shop}' in ${sourceFunction} of ${sourceFile} ${
            id ? `-> ${id}` : ""
        }: ${error}`,
        function (event) {
            event.addMetadata("data", {
                shop,
                sourceFunction,
                sourceFile,
                data,
            });
        },
    );
}

export function getVariantName(productTitle, variantTitle) {
    return (
        productTitle +
        (variantTitle &&
        variantTitle != null &&
        variantTitle != "" &&
        variantTitle != "Default Title"
            ? " - " + variantTitle
            : "")
    );
}

export function dbAddressToGraphQl(address) {
    return {
        firstName: address.firstName,
        lastName: address.lastName,
        company: address.company ?? "",
        address1: address.address1,
        address2: address.address2,
        zip: address.zip,
        city: address.city,
        country: address.country,
        countryCode: address.countryCode,
        province: address.province,
        provinceCode: address.provinceCode,
        phone: address.phone
    };
}

export function generateCpLink(
    myshopifyDomain,
    merchantToken,
    customerShopifyId,
    contractShopifyId = null,
    urlParams = {},
) {
    const proxyPath =
        process.env.ENV === "production"
            ? `/a/loop_subscriptions`
            : `/a/dev_loop_subscriptions`;
    let _urlParams = new URLSearchParams();
    _urlParams.append("merchantToken", merchantToken);
    let _url = `https://${myshopifyDomain}${proxyPath}/customer/${customerShopifyId}`;
    if (contractShopifyId) {
        _url += `/subscription/${contractShopifyId}`;
    }
    if (urlParams) {
        for (let _key in urlParams) {
            _urlParams.append(_key, urlParams[_key]);
        }
    }
    _url += `?${_urlParams.toString()}`;
    return _url;
}

export function findChangesInPresetVarinats(arr1, arr2) {
    const changes = [];

    // Create a map for quick lookup of childProducts by productVariantId
    const arr1Map = new Map(
        arr1.map((item) => [item.productVariantId, item.childProducts]),
    );
    const arr2Map = new Map(
        arr2.map((item) => [item.productVariantId, item.childProducts]),
    );

    // Function to detect changes in child products
    function detectChanges(variantId, childProducts1, childProducts2) {
        const childProductsMap1 = new Map(
            childProducts1.map((child) => [
                child.childProductId,
                child.quantity,
            ]),
        );
        const childProductsMap2 = new Map(
            childProducts2.map((child) => [
                child.childProductId,
                child.quantity,
            ]),
        );

        // Find removed and changed child products
        childProducts1.forEach((child) => {
            if (childProductsMap2.has(child.childProductId)) {
                const newQuantity = childProductsMap2.get(child.childProductId);
                if (newQuantity !== child.quantity) {
                    changes.push({
                        productVariantId: variantId,
                        childProductId: child.childProductId,
                        oldQuantity: child.quantity,
                        newQuantity: newQuantity,
                        changeType: "quantityChange",
                    });
                }
            } else {
                changes.push({
                    productVariantId: variantId,
                    childProductId: child.childProductId,
                    oldQuantity: child.quantity,
                    newQuantity: 0,
                    changeType: "childProductRemoved",
                });
            }
        });

        // Find added child products
        childProducts2.forEach((child) => {
            if (!childProductsMap1.has(child.childProductId)) {
                changes.push({
                    productVariantId: variantId,
                    childProductId: child.childProductId,
                    oldQuantity: 0,
                    newQuantity: child.quantity,
                    changeType: "childProductAdded",
                });
            }
        });
    }

    // Check each variant in arr1 for changes
    arr1.forEach((item) => {
        const variantId = item.productVariantId;
        if (arr2Map.has(variantId)) {
            detectChanges(
                variantId,
                item.childProducts,
                arr2Map.get(variantId),
            );
        } else {
            // If a variant from arr1 is not in arr2, all its child products are considered removed
            item.childProducts.forEach((child) => {
                changes.push({
                    productVariantId: variantId,
                    childProductId: child.childProductId,
                    oldQuantity: child.quantity,
                    newQuantity: 0,
                    changeType: "variantRemoved",
                });
            });
        }
    });

    // Check for new variants added in arr2 that are not in arr1
    arr2.forEach((item) => {
        if (!arr1Map.has(item.productVariantId)) {
            item.childProducts.forEach((child) => {
                changes.push({
                    productVariantId: item.productVariantId,
                    childProductId: child.childProductId,
                    oldQuantity: 0,
                    newQuantity: child.quantity,
                    changeType: "newVariant",
                });
            });
        }
    });

    return changes;
}

export function convertToTitleCase(string) {
    return string
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
}

export function isValidDomain(str) {
    let regex = new RegExp(
        /^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/,
    );

    if (str == null) {
        return "false";
    }

    if (regex.test(str) == true) {
        return true;
    } else {
        return false;
    }
}

export function isValidSubdomain(str) {
    let regex = new RegExp(/^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*/);

    if (str == null) {
        return "false";
    }

    if (regex.test(str) == true) {
        return true;
    } else {
        return false;
    }
}

export function checkIfActionValidOnPrepaidV2 (isContractPrepaid, featureKey, featureSwitches) {
    if (!isContractPrepaid) return true;
    return featureSwitches.includes(featureKey);
}

export function handlePageBreadCrumb(router) {
   return router.push(`${router.asPath.substring(0, router.asPath.lastIndexOf("/"))}`,`${router.asPath.substring(0, router.asPath.lastIndexOf("/"))}`,{shallow: true});
}

export function checkIfInventoryOutOfStock(lineItem) {
    if( (lineItem.inventory_management !== null) &&
        (lineItem.inventory_policy === "deny") ) {
            if((Number((lineItem.inventory_quantity || 0) - (lineItem.quantity || 0)) < 0)){
                return true;
            } else {
                return false;
            }
    } else {
        return false;
    }
}

export function paginate(list, pageSize, pageNumber) {
    const totalEntries = list.length;
    const totalPages = Math.ceil(totalEntries / pageSize);
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    const entries = list.slice(startIndex, endIndex);
    const hasNextPage = pageNumber < totalPages;

    return {
        entries: entries,
        hasNextPage: hasNextPage
    };
}
export function remainingOrdersInCurrentPrepaidV2Cycle(billingAttemptSuccessCount, ordersPerCharge) {
  const remainder = billingAttemptSuccessCount % ordersPerCharge;
  if (remainder === 0) return 0;
  return ordersPerCharge - remainder;
}

/**
 * @param {string} str
 * @returns {number}
 */
export function convertToInteger(str) {
  // Use parseInt to convert the string to an integer
  const result = parseInt(str.trim(), 10);

  // Check if the result is NaN (Not a Number)
  if (isNaN(result)) return 0;

  // Return the integer result
  return result;
}

/**
 * @param {number} num
 * @param {number} min
 * @param {number} max
 * @returns {number}
 */
export function clamp(num, min, max) {
  if (num < min) return min;
  if (num > max) return max;
  return num;
}
