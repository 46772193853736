import { useState, useEffect, useCallback } from "react";
import { Link, Banner, Page } from "@shopify/polaris";
import { isSubscriptionsEligible } from "../utils/queries";
import { useAxios } from "../hooks/useAxios";
import { useRouter } from "next/router";
import { handleAdminError } from "../utils/helpers";

function Disclaimer(props) {
  const router = useRouter();
  const [axios] = useAxios();
  const [discIsConfirmed, setdiscIsConfirmed] = useState(true);

  const getStatus = useCallback(async () => {
    try {
      let res = await isSubscriptionsEligible(axios, props.shopOrigin);
      setdiscIsConfirmed(res.data.shop.features.eligibleForSubscriptions);
    } catch (e) {
      handleAdminError(e, props.shopOrigin, "getStatus", "Disclaimer", "", {
        params: { shopOrigin: props.shopOrigin },
      });
      // Bugsnag.notify(e, function (event) {
      //   event.addMetadata("data", {
      //     shop: props.shopOrigin,
      //     function: "Disclaimer.getStatus",
      //   });
      // });
      setdiscIsConfirmed(false);
    }
  }, [setdiscIsConfirmed]);

  useEffect(() => {
    getStatus();
  }, []);

  return (
    <>
      {discIsConfirmed == true ? null : (
        <Page>
          <Banner status="critical">
            Your store is not eligible for selling subscriptions. Please set up
            either <strong>Shopify Payments</strong>,{" "}
            <strong>PayPal Express</strong> or <strong>Stripe</strong> as your
            payment gateway. If you have <strong>PayPal Express</strong>{" "}
            activated in your shop, you can also create one selling plan to
            start the approval process.{" "}
            <Link
              onClick={() => {
                router.push("/settings/paypal", "/settings/paypal", {
                  shallow: true,
                });
              }}
            >
              Click here
            </Link>{" "}
            to see detailed instructions to enable{" "}
            <strong>PayPal Express</strong>.
          </Banner>
        </Page>
      )}
    </>
  );
}
export default Disclaimer;
