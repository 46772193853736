import _ from "lodash";

import { getOrdinal } from "./helpers";

export const ACTION_SKIP_NEXT = "SKIP";

export const BILLING_ATTEMPT_STATUS_FOR_A_CONTRACT_DONE = "done";
export const BILLING_ATTEMPT_STATUS_FOR_A_CONTRACT_IN_PROGRESS = "in_progress";

export const SUBS_STATUS_ACTIVE = 1;
export const SUBS_STATUS_PAUSED = 4;
export const SUBS_STATUS_CANCELLED = 2;
export const SUBS_STATUS_EXPIRED = 5;
export const SUBS_STATUS_FAILED = 3;

export const SHOPIFY_SUBS_STATUS_ACTIVE = "ACTIVE";
export const SHOPIFY_SUBS_STATUS_PAUSED = "PAUSED";
export const SHOPIFY_SUBS_STATUS_CANCELLED = "CANCELLED";
export const SHOPIFY_SUBS_STATUS_EXPIRED = "EXPIRED";
export const SHOPIFY_SUBS_STATUS_FAILED = "FAILED";

export const BUNDLE = "BUNDLE";
export const BUNDLE_DESCRIPTION_ACTIVE = "ACTIVE";

export const subs_status_obj = {
    1: "Active",
    2: "Cancelled",
    3: "Failed",
    4: "Paused",
    5: "Expired",
};

export const subs_status_obj_to_shopify = {
    1: "ACTIVE",
    2: "CANCELLED",
    3: "FAILED",
    4: "PAUSED",
    5: "EXPIRED",
};

export const shopify_subs_status_obj = {
    ACTIVE: 1,
    CANCELLED: 2,
    FAILED: 3,
    PAUSED: 4,
    EXPIRED: 5,
};

export const ORDER_STATUS_SUCCESS = 1;
export const ORDER_STATUS_UNPROCESSED = 2;
export const ORDER_STATUS_SKIPPED = 3;
export const ORDER_STATUS_FAILED = 4;
export const ORDER_STATUS_PROCESSING = 5;
export const ORDER_STATUS_FAILED_NO_RETRY = 6;
export const ORDER_STATUS_SUCCESS_LABEL = "Paid";
export const ORDER_STATUS_UNPROCESSED_LABEL = "Scheduled";
export const ORDER_STATUS_SKIPPED_LABEL = "Skipped";
export const ORDER_STATUS_FAILED_LABEL = "Failed";
export const ORDER_STATUS_PROCESSING_LABEL = "Processing";
export const ORDER_STATUS_FAILED_NO_RETRY_LABEL = "Failed";

export const INTEGRATION_UNAVAILABLE = 0;
export const INTEGRATION_DISCONNECTED = 1;
export const INTEGRATION_CONNECTED = 2;
export const INTEGRATION_OUTLINK = 4;

export const FREQ_DAY = "DAY";
export const FREQ_WEEK = "WEEK";
export const FREQ_MONTH = "MONTH";
export const FREQ_YEAR = "YEAR";

export const FREQ_OPTIONS = [
    { label: "Day(s)", value: FREQ_DAY },
    { label: "Week(s)", value: FREQ_WEEK },
    { label: "Month(s)", value: FREQ_MONTH },
    { label: "Year(s)", value: FREQ_YEAR },
];
export const DISC_OPTIONS = [
    { label: "None", value: "NONE" },
    { label: "Fixed amount", value: "FIXED_AMOUNT" },
    { label: "Percentage", value: "PERCENTAGE" },
    { label: "Price", value: "PRICE" },
];

export const QUICK_ACTIONS_DISC_OPTIONS = [
    { label: "None", value: "NONE" },
    { label: "Fixed amount", value: "FIXED_AMOUNT" },
    { label: "Percentage", value: "PERCENTAGE" },
];

export const SWAP_DISCOUNT_OPTIONS = {
    SAME: 'same',
    OLD: 'old',
    NEW: 'new',
    CUSTOM: 'custom',
}

export const MIN_CYCLE_OPTIONS = [
    { label: "Unset", value: "null" },
    { label: "1", value: "1" },
    ..._.range(2, 99).map((m) => {
        return { label: m, value: m + "" };
    }),
];
export const MAX_CYCLE_OPTIONS = [
    { label: "Unlimited", value: "null" },
    { label: "1", value: "1" },
    ..._.range(2, 99).map((m) => {
        return { label: m, value: m + "" };
    }),
];

export const DISCOUNT_AFTER_OPTIONS = [
    { label: "Initial Order", value: "1" },
    { label: "2nd payment", value: "2" },
    { label: "3rd payment", value: "3" },
    ..._.range(4, 30).map((m) => {
        return { label: m + "th payment", value: m + "" };
    }),
];

export const SUBSCRIPTION_STATUS_OPTIONS = [
    {
        label: subs_status_obj[SUBS_STATUS_ACTIVE],
        value: SUBS_STATUS_ACTIVE,
    },
    {
        label: subs_status_obj[SUBS_STATUS_PAUSED],
        value: SUBS_STATUS_PAUSED,
    },
    {
        label: subs_status_obj[SUBS_STATUS_CANCELLED],
        value: SUBS_STATUS_CANCELLED,
    },
    {
        label: subs_status_obj[SUBS_STATUS_FAILED],
        value: SUBS_STATUS_FAILED,
    },
    {
        label: subs_status_obj[SUBS_STATUS_EXPIRED],
        value: SUBS_STATUS_EXPIRED,
    },
];

export const CURRENCY_SYMBOLS = {
    USD: "$", // US Dollar
    EUR: "€", // Euro
    CRC: "₡", // Costa Rican Colón
    GBP: "£", // British Pound Sterling
    ILS: "₪", // Israeli New Sheqel
    INR: "₹", // Indian Rupee
    JPY: "¥", // Japanese Yen
    KRW: "₩", // South Korean Won
    NGN: "₦", // Nigerian Naira
    PHP: "₱", // Philippine Peso
    PLN: "zł", // Polish Zloty
    PYG: "₲", // Paraguayan Guarani
    THB: "฿", // Thai Baht
    UAH: "₴", // Ukrainian Hryvnia
    VND: "₫", // Vietnamese Dong
};

export const ANCHOR_DAY_WEEK_OPTIONS = [
    { label: "Disabled", value: "DISABLED" },
    { label: "Monday", value: "1" },
    { label: "Tuesday", value: "2" },
    { label: "Wednesday", value: "3" },
    { label: "Thursday", value: "4" },
    { label: "Friday", value: "5" },
    { label: "Saturday", value: "6" },
    { label: "Sunday", value: "7" },
];

export const ANCHOR_DAY_OPTIONS = [
    { label: "Disabled", value: "DISABLED" },
    ..._.range(1, 32).map((m) => {
        return {
            label: getOrdinal(m.toString()) + " of the month",
            value: m + "",
        };
    }),
];

export const ANCHOR_MONTH_OPTIONS = [
    { label: "January", value: "1" },
    { label: "February", value: "2" },
    { label: "March", value: "3" },
    { label: "April", value: "4" },
    { label: "May", value: "5" },
    { label: "June", value: "6" },
    { label: "July", value: "7" },
    { label: "August", value: "8" },
    { label: "September", value: "9" },
    { label: "October", value: "10" },
    { label: "November", value: "11" },
    { label: "December", value: "12" },
];

export const PREANCHOR_BEHAVIOR = [
    { label: "On Checkout", value: "ASAP" },
    { label: "On Anchor", value: "NEXT" },
];

export const BILLING_EVERY_OPTIONS = [
    { label: "1 delivery", value: "1" },
    ..._.range(2, 51).map((m) => {
        return { label: m + " deliveries", value: m + "" };
    }),
];

export const BILLING_RETRY_COUNT_OPTIONS = [
    { label: "Don't Retry", value: "0" },
    { label: "1 time", value: "1" },
    ..._.range(2, 11).map((m) => {
        return { label: m + " times", value: m + "" };
    }),
];

export const BILLING_RETRY_DELAY_COUNT_OPTIONS = [
    { label: "1 day", value: "1" },
    { label: "2 days", value: "2" },
    { label: "3 days", value: "3" },
    { label: "4 days", value: "4" },
    { label: "5 days", value: "5" },
    { label: "7 days", value: "7" },
    { label: "14 days", value: "14" },
];

export const CUTOFF_TO_POSTPONE_ORDERS = [
    { label: "3 days", value: 3 },
    { label: "4 days", value: 4 },
    { label: "5 days", value: 5 },
    { label: "6 days", value: 6 },
    { label: "7 days", value: 7 },
    { label: "8 days", value: 8 },
    { label: "9 days", value: 9 },
    { label: "10 days", value: 10 },
    { label: "11 days", value: 11 },
    { label: "12 days", value: 12 },
    { label: "13 days", value: 13 },
    { label: "14 days", value: 14 },
    { label: "15 days", value: 15 },
];

export const BILLING_RETRY_FAILED_ACTION = [
    { label: "Skip the payment", value: "SKIP" },
    { label: "Pause subscription", value: "PAUSED" },
    { label: "Cancel subscription", value: "CANCEL" },
];

export const SKIP_BILLING_RETRY_FAILED_ACTION = [
    { label: "Pause subscription", value: "PAUSED" },
    { label: "Cancel subscription", value: "CANCEL" },
];

export const SUCCESSIVE_BILLING_CYCLES_OPTION_AFTER_FAILURE = [
    { label: "2 successive billing cycles", value: 2 },
    { label: "3 successive billing cycles", value: 3 },
    { label: "4 successive billing cycles", value: 4 },
    { label: "5 successive billing cycles", value: 5 },
];

export const GLOBAL_BILLING_HOURS = [
    { label: "Not Set", value: "Not Set" },
    { label: "0:00", value: "0" },
    { label: "1:00", value: "1" },
    { label: "2:00", value: "2" },
    { label: "3:00", value: "3" },
    { label: "4:00", value: "4" },
    { label: "5:00", value: "5" },
    { label: "6:00", value: "6" },
    { label: "7:00", value: "7" },
    { label: "8:00", value: "8" },
    { label: "9:00", value: "9" },
    { label: "10:00", value: "10" },
    { label: "11:00", value: "11" },
    { label: "12:00", value: "12" },
    { label: "13:00", value: "13" },
    { label: "14:00", value: "14" },
    { label: "15:00", value: "15" },
    { label: "16:00", value: "16" },
    { label: "17:00", value: "17" },
    { label: "18:00", value: "18" },
    { label: "19:00", value: "19" },
    { label: "20:00", value: "20" },
    { label: "21:00", value: "21" },
    { label: "22:00", value: "22" },
    { label: "23:00", value: "23" },
];

export const HOURS = [
    { label: "00:00", value: "00:00" },
    { label: "01:00", value: "01:00" },
    { label: "02:00", value: "02:00" },
    { label: "03:00", value: "03:00" },
    { label: "04:00", value: "04:00" },
    { label: "05:00", value: "05:00" },
    { label: "06:00", value: "06:00" },
    { label: "07:00", value: "07:00" },
    { label: "08:00", value: "08:00" },
    { label: "09:00", value: "09:00" },
    { label: "10:00", value: "10:00" },
    { label: "11:00", value: "11:00" },
    { label: "12:00", value: "12:00" },
    { label: "13:00", value: "13:00" },
    { label: "14:00", value: "14:00" },
    { label: "15:00", value: "15:00" },
    { label: "16:00", value: "16:00" },
    { label: "17:00", value: "17:00" },
    { label: "18:00", value: "18:00" },
    { label: "19:00", value: "19:00" },
    { label: "20:00", value: "20:00" },
    { label: "21:00", value: "21:00" },
    { label: "22:00", value: "22:00" },
    { label: "23:00", value: "23:00" },
];

export const ORDINAL_NUMBER = [
    "Zeroth",
    "First",
    "Second",
    "Third",
    "Fourth",
    "Fifth",
    "Sixth",
    "Seventh",
    "Eighth",
    "Ninth",
    "tenth",
    "eleventh",
    "twelfth",
    "thirteenth",
    "fourteenth",
    "fifteenth",
    "sixteenth",
    "seventeenth",
    "eighteenth",
    "nineteenth",
];
export const dateFormatOptions = [
    {
        label: "YYYY/MM/DD (2030/30/12)",
        value: "YYYY,MM,DD",
        requires_separator: true,
    },
    {
        label: "YYYY/DD/MM (2030/12/30)",
        value: "YYYY,DD,MM",
        requires_separator: true,
    },
    {
        label: "MM/DD/YYYY (12/30/2030)",
        value: "MM,DD,YYYY",
        requires_separator: true,
    },
    {
        label: "DD/MM/YYYY (30/12/2030)",
        value: "DD,MM,YYYY",
        requires_separator: true,
    },
];

export const dateSeparator = [
    {
        label: "/",
        value: "/",
    },
    {
        label: "-",
        value: "-",
    },
    {
        label: ".",
        value: ".",
    },
];

export const manualDiscountTypes = [
    { label: "None", value: "NONE" },
    { label: "Percentage", value: "PERCENTAGE" },
    { label: "Fixed amount", value: "FIXED_AMOUNT" },
];

export const activeDraftStatusOptions = [
    { label: "Draft", value: "DRAFT" },
    { label: "Active", value: "ACTIVE" },
];

export const benefitsConditions = [
    /**SUBSCRIPTION_CONTRACT */
    {
        conditionId: 4,
        conditionKey: "sellingPlanPresent",
        conditionTitle: "Type of subscription plan",
        conditionCategory: "SUBSCRIPTION_CONTRACT",
        conditionInputType: "DROPDOWN",
        conditionOperator: "EQUAL",
        conditionFunction: "sellingPlanPresent",
        conditionVisibility: 1,
    },
    {
        conditionId: 6,
        conditionKey: "totalOrders",
        conditionTitle: "Total specific subscription orders",
        conditionCategory: "SUBSCRIPTION_CONTRACT",
        conditionInputType: "NUMBER",
        conditionOperator: "EQUAL",
        conditionFunction: "orderNumberFilter",
        conditionVisibility: 1,
    },
    {
        conditionId: 25,
        conditionKey: "totalSpecificSubscriptionSpend",
        conditionTitle: "Total specific subscription spent",
        conditionCategory: "SUBSCRIPTION_CONTRACT",
        conditionInputType: "NUMBER",
        conditionOperator: "EQUAL",
        conditionFunction: "specificSubscriptionSpendFilter",
        conditionVisibility: 1,
    },
    {
        conditionId: 11,
        conditionKey: "discountCodePresent",
        conditionTitle: "Discount code present",
        conditionCategory: "SUBSCRIPTION_CONTRACT",
        conditionInputType: "TEXT",
        conditionOperator: "EQUAL",
        conditionFunction: "discountCodePresentFilter",
        conditionVisibility: 1,
    },
    {
        conditionId: 12,
        conditionKey: "discountCodeAbsent",
        conditionTitle: "Discount code not present",
        conditionCategory: "SUBSCRIPTION_CONTRACT",
        conditionInputType: "TEXT",
        conditionOperator: "EQUAL",
        conditionFunction: "discountCodeAbsentFilter",
        conditionVisibility: 1,
    },
    /** PRODUCT */
    {
        conditionId: 1,
        conditionKey: "product",
        conditionTitle: "Products in subscription (any of)",
        conditionCategory: "PRODUCT",
        conditionInputType: "PRODUCT_MODAL",
        conditionOperator: "IN",
        conditionFunction: "productFilter",
        conditionVisibility: 1,
    },
    {
        conditionId: 21,
        conditionKey: "productNotPresent",
        conditionTitle: "Products not in subscription (any of)",
        conditionCategory: "PRODUCT",
        conditionInputType: "PRODUCT_MODAL",
        conditionOperator: "IN",
        conditionFunction: "productNotPresentFilter",
        conditionVisibility: 1,
    },
    {
        conditionId: 18,
        conditionKey: "collectionProduct",
        conditionTitle: "Specific collection products present (any of)",
        conditionCategory: "PRODUCT",
        conditionInputType: "MODAL",
        conditionOperator: "EQUAL",
        conditionFunction: "collectionProductFilter",
        conditionVisibility: 1,
    },
    {
        conditionId: 5,
        conditionKey: "productQuantities",
        conditionTitle: "Number of products in subscription",
        conditionCategory: "PRODUCT",
        conditionInputType: "NUMBER",
        conditionOperator: "EQUAL",
        conditionFunction: "quantityOfProducts",
        conditionVisibility: 1,
    },
    {
        conditionId: 22,
        conditionKey: "totalWeightInGrams",
        conditionTitle: "Weight of products (in grams)",
        conditionCategory: "PRODUCT",
        conditionInputType: "NUMBER",
        conditionOperator: "EQUAL",
        conditionFunction: "totalWeightInGramsFilter",
        conditionVisibility: 1,
    },
    {
        conditionId: 19,
        conditionKey: "productTitle",
        conditionTitle: "Product title",
        conditionCategory: "PRODUCT",
        conditionInputType: "TEXT",
        conditionOperator: "IN",
        conditionFunction: "productTitleFilter",
        conditionVisibility: 1,
    },
    {
        conditionId: 20,
        conditionKey: "variantTitle",
        conditionTitle: "Variant title",
        conditionCategory: "PRODUCT",
        conditionInputType: "TEXT",
        conditionOperator: "IN",
        conditionFunction: "variantTitleFilter",
        conditionVisibility: 1,
    },
    /**CUSTOMER */
    {
        conditionId: 13,
        conditionKey: "allOrders",
        conditionTitle: "Total customer orders",
        conditionCategory: "CUSTOMER",
        conditionInputType: "NUMBER",
        conditionOperator: "EQUAL",
        conditionFunction: "customerTotalOrdersFilter",
        conditionVisibility: 1,
    },
    {
        conditionId: 14,
        conditionKey: "totalSpent",
        conditionTitle: "Total customer spent",
        conditionCategory: "CUSTOMER",
        conditionInputType: "NUMBER",
        conditionOperator: "EQUAL",
        conditionFunction: "customerTotalSpentFilter",
        conditionVisibility: 1,
    },
    {
        conditionId: 23,
        conditionKey: "totalSubscriptionOrders",
        conditionTitle: "Total subscription orders",
        conditionCategory: "CUSTOMER",
        conditionInputType: "NUMBER",
        conditionOperator: "EQUAL",
        conditionFunction: "totalSubscriptionOrdersFilter",
        conditionVisibility: 1,
    },
    {
        conditionId: 24,
        conditionKey: "totalSubscriptionSpend",
        conditionTitle: "Total subscription spent",
        conditionCategory: "CUSTOMER",
        conditionInputType: "NUMBER",
        conditionOperator: "EQUAL",
        conditionFunction: "totalSubscriptionSpendFilter",
        conditionVisibility: 1,
    },
    {
        conditionId: 2,
        conditionKey: "customerTagPresent",
        conditionTitle: "Customer tag present",
        conditionCategory: "CUSTOMER",
        conditionInputType: "TEXT",
        conditionOperator: "EQUAL",
        conditionFunction: "customerTagPresentFilter",
        conditionVisibility: 1,
    },
    {
        conditionId: 3,
        conditionKey: "customerTagAbsent",
        conditionTitle: "Customer tag not present",
        conditionCategory: "CUSTOMER",
        conditionInputType: "TEXT",
        conditionOperator: "EQUAL",
        conditionFunction: "customerTagAbsentFilter",
        conditionVisibility: 1,
    },
];

export const userPermissionAccessType = {
    WRITE: "WRITE",
    READ: "READ",
    NO_ACCESS: "NO_ACCESS",
};

export const permission = {
    SUBSCRIPTION_DISCOUNT: "subscriptionDiscount",
    SUBSCRIPTION_PRODUCT: "subscriptionProduct",
    SUBSCRIPTION_SHIPPING: "subscriptionShipping",
    SUBSCRIPTION_PAYMENT_DETAILS: "subscriptionPaymentDetails",
    SUBSCRIPTION_ORDER_ACTIONS: "subscriptionOrderActions",
    ORDER_NOTES_AND_ATTRIBUTES: "orderNotesAndAttributes",
    ACCESS_CUSTOMER_PORTAL: "accessCustomerPortal",
    CREATE_SUBSCRIPTION: "createSubscription",
    SUBSCRIPTION_DETAILS: "subscriptionDetails",
    CHANGE_SUBSCRIPTION_STATUS: "changeSubscriptionStatus",
    FLOW_MANAGEMENT: "flowManagement",
    ACCOUNT_LOGS: "accountLogs",
    MANAGE_API_TOKENS: "manageApiToken",
    SUBSCRIPTION_SHIPPING_RATES: "subscriptionShippingRates",
    BULK_ACTIONS: "bulkActions",
    EXPORT_REPORTS: "exportReports",
    VIEW_REPORTS: "viewReports",
    ANALYTICS_OVERVIEW: "analyticsOverview",
    CANCELLATION_ANALYTICS: "cancellationAnalytics",
    DUNNING_ANALYTICS: "dunningAnalytics",
    HOME_DASHBOARD: "homeDashboard",
    UPDATE_USER_PERMISSION: "updateUserPermission",
    UPDATE_USER_TYPE: "updateUserType",
    CHECKOUT_LINKS: "checkoutLinks",
    EVENT_ACTIONS_CAMPAGIN_ACTIONS: "quickActionsEventActions",
    CAMPAGIN_ACTIONS_QUICK_ACTIONS: "quickActionsCampaignActions",
    INTEGRATIONS: "integrations",
    SELLING_PLAN: "sellingPlan",
    BUNDLE_PREFERENCES: "bundlePreferences",
    BUNDLE_MANAGEMENT: "bundleManagement",
    BUNDLE_SNIPPETS: "bundleSnippets",
    PAYPAL_EXPRESS_SETUP: "paypalExpressSetup",
    MIGRATE_CUSTOMER: "migrateCustomerAndPaymentMethod",
    AUTO_UPDATES: "autoUpdates",
    INVENTORY: "inventory",
    ORDER_SCHEDULE: "orderSchedule",
    SUBSCRIPTION_TAGS: "subscriptionTags",
    GENERAL: "generalSettings",
    PRICING: "pricingSettings",
    NOTIFICATION: "notificationSettings",
    MULTILINGUAL_TEXTS: "multilingualTexts",
    ACCESSIBILITY: "accessibility",
    PREFERENCES: "preferences",
    CUSTOMER_PORTAL_TEXTS: "customerPortalTexts",
    UPSELL: "upsell",
    SWAP: "productSwap",
    THEMES: "themes",
    CANCELLATION_FLOWS: "cancellationFlows",
    WIDGET: "widgetConfigurationAndInstallation",
    ALERT_CENTRE: "alertCentre",
    UPSELL_ANALYTICS: "upsellAnalytics",
    ORDER_ANALYTICS: "orderAnalytics",
    PREPAID_CREDITS: "prepaidCredits",
    DESIGNATE_OWNER: "designateOwner",
    DELETE_USER: "deleteUser",
    PAYMENT_RECOVERY: "paymentRecovery"
};

export const USER_TYPES = {
    ADMIN: "ADMIN",
    MEMBER: "MEMBER",
    OWNER: "OWNER",
    LOOP_USER: "LOOP_USER",
};

export const userTypeMapping = {
    ADMIN: "Admin",
    OWNER: "Owner",
    MEMBER: "Member",
    LOOP_USER: "Loop user",
};

export const FEATURE_SWITCHES = {
    PREPAIDV2_SELLING_PLAN_GROUPS: "PREPAIDV2_SELLING_PLAN_GROUPS",
    PREPAIDV2_CONDITION_SELLING_PLANS: "PREPAIDV2_CONDITION_SELLING_PLANS",
    PREPAIDV2_SWAP_PRODUCT: "PREPAIDV2_SWAP_PRODUCT",
    PREPAIDV2_UPDATE_FREQUENCY: "PREPAIDV2_UPDATE_FREQUENCY",
    PREPAIDV2_CANCELLATION_OFFER: "PREPAIDV2_CANCELLATION_OFFER",
    PREPAIDV2_ADD_DISCOUNT: "PREPAIDV2_ADD_DISCOUNT",
    PREPAIDV2_REMOVE_DISCOUNT: "PREPAIDV2_REMOVE_DISCOUNT",
    PREPAIDV2_REMOVE_LINE: "PREPAIDV2_REMOVE_LINE",
    PREPAIDV2_ADD_LINE: "PREPAIDV2_ADD_LINE",
    PREPAIDV2_EDIT_LINE: "PREPAIDV2_EDIT_LINE",
    PREPAIDV2_FLOWS: "PREPAIDV2_FLOWS",
    PREPAIDV2_BULK_ACTION_UPDATE_CONTRACT_STATUS:
        "PREPAIDV2_BULK_ACTION_UPDATE_CONTRACT_STATUS",
    PREPAIDV2_BULK_ACTION_RECALCULATE_LINE_PRICES:
        "PREPAIDV2_BULK_ACTION_RECALCULATE_LINE_PRICES",
    PREPAIDV2_BULK_ACTION_RECALCULATE_SHIPPING:
        "PREPAIDV2_BULK_ACTION_RECALCULATE_SHIPPING",
    PREPAIDV2_BULK_ACTION_REMOVE_DISCOUNTS:
        "PREPAIDV2_BULK_ACTION_REMOVE_DISCOUNTS",
    PREPAIDV2_BULK_ACTION_ADD_DISCOUNTS: "PREPAIDV2_BULK_ACTION_ADD_DISCOUNTS",
    PREPAIDV2_BULK_ACTION_SWAP_MULTIPLE_PRODUCTS:
        "PREPAIDV2_BULK_ACTION_SWAP_MULTIPLE_PRODUCTS",
    PREPAIDV2_BULK_ACTION_SWAP_SINGLE_PRODUCT:
        "PREPAIDV2_BULK_ACTION_SWAP_SINGLE_PRODUCT",
    PREPAIDV2_BULK_ACTION_REMOVE_SUBSCRIPTION_PRODUCT:
        "PREPAIDV2_BULK_ACTION_REMOVE_SUBSCRIPTION_PRODUCT",
    PREPAIDV2_BULK_ACTION_REMOVE_PRODUCT_ONCE:
        "PREPAIDV2_BULK_ACTION_REMOVE_PRODUCT_ONCE",
    PREPAIDV2_BULK_ACTION_ADD_SUBSCRIPTION_PRODUCT:
        "PREPAIDV2_BULK_ACTION_ADD_SUBSCRIPTION_PRODUCT",
    PREPAIDV2_BULK_ACTION_ADD_ONE_TIME_PRODUCT:
        "PREPAIDV2_BULK_ACTION_ADD_ONE_TIME_PRODUCT",
    PREPAIDV2_UPSELL: "PREPAIDV2_UPSELL",
    PREPAIDV2_APPLY_CUSTOM_PLAN: "PREPAIDV2_APPLY_CUSTOM_PLAN",
    PREPAIDV2_UPDATE_MIN_BILLING_CYCLES: "PREPAIDV2_UPDATE_MIN_BILLING_CYCLES",
    PREPAIDV2_UPDATE_MAX_BILLING_CYCLES: "PREPAIDV2_UPDATE_MAX_BILLING_CYCLES",
    PREPAIDV2_RECALCULATE_DISCOUNTS: "PREPAIDV2_RECALCULATE_DISCOUNTS",
    PREPAIDV2_QUICK_ACTIONS: "PREPAIDV2_QUICK_ACTIONS",
    PREPAIDV2_CAMPAIGN_ACTIONS: "PREPAIDV2_CAMPAIGN_ACTIONS",
    PREPAIDV2_CHECKOUT_LINKS: "PREPAIDV2_CHECKOUT_LINKS",
    PREPAIDV2_SELLING_PLAN_MAX_MIN_CYCLES:
        "PREPAIDV2_SELLING_PLAN_MAX_MIN_CYCLES",
    PREPAIDV2_SELLING_PLAN_COLLECTION_MAPPING:
        "PREPAIDV2_SELLING_PLAN_COLLECTION_MAPPING",
};

export const paymentMethodImage = Object.freeze({
    paypal: "https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/paypal-express--XwN1he2Bl3G.svg",
    shoppay: "https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/shop-pay-BXFIrczgXY-c.svg",
    americanexpress:"https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/american-express-C5u3GtBWzgsC.svg",
    discover:"https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/discover-BV9fGXxjUnzR.svg",
    visa:"https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/visa-DxUoA7kmA-Jc.svg",
    mastercard:"https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/mastercard-CTGikZxOFT4y.svg",
    bogus:"https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/bogus-CIsYlO1zAqPE.svg",
    maestro:"https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/maestro-CWRaJ3hXtJ4L.svg",
    unionpay:"https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/unionpay-BQUSQqcIKgHx.svg",
    jcb:"https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/jcb-Dr6oHwV4Fzzq.svg",
    dinersclub:"https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/diners-club-AnBRedOkpZlg.svg",
    others:"https://www.flaticon.com/svg/vstatic/svg/3916/3916663.svg?token=exp=1715946484~hmac=f0e31dd1a39a6581c1546872842f0880"
});

export const customerBasedVariables = [
    "customer_first_name",
    "customer_last_name",
    "customer_email"
];

export const storeBasedVariables = [
    "store_url",
    "store_name",
    "store_address",
    "store_support_email",
];

export const subscriptionBasedVariables = [
    "subscription_discount",
    "subscription_total_line_item_price",
    "subscription_total_product_discount",
    "subscription_total_line_item_discounted_price",
    "subscription_shipping_price",
    "subscription_total_price",
    "subscription_next_billing_date",
    "subscription_charge_offset",
    "subscription_next_delivery_date",
    "subscription_billing_interval",
    "subscription_spent",
    "subscription_order_count"
];
