import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import { Link, Banner, Page } from "@shopify/polaris";
import { useAxios } from "../hooks/useAxios";

function FreeTrial(props) {
  const router = useRouter();
  const [disable, setDisable] = useState(true);
  const [axios] = useAxios();

  useEffect(() => {
    (async () => {
      let resp = await axios.post(
        process.env.HOST + `/settings/is_free_plan_limit_exceeded`,
        {
          myshopify_domain: props.shopOrigin,
        }
      );
      let res = null;
      if (resp.data) {
        res = resp.data;
      } else {
        res = resp;
      }
      if (res.expired) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    })();
  }, []);
  return (
    <>
      {disable ? null : (
        <Page>
          <Banner status="warning">
            You have exceeded the free plan limit of 50 subscriptions. To
            continue accepting new subscriptions, please upgrade your plan from
            the{" "}
            <Link
              onClick={() => {
                router.push("/settings/pricing", "/settings/pricing", {
                  shallow: true,
                });
              }}
            >
              pricing plan
            </Link>{" "}
            page. Please{" "}
            <Link
              onClick={() => {
                Beacon("open");
                Beacon("navigate", "/ask/message");
              }}
            >
              contact us
            </Link>{" "}
            if you need any help.
          </Banner>
        </Page>
      )}
    </>
  );
}
export default FreeTrial;
