import { Stack, TopBar, Icon, Text } from "@shopify/polaris";
import { useState, useCallback } from "react";
import HeadwayWidget from "@headwayapp/react-widget";
import {
    LogOutMinor,
    QuestionMarkMinor,
    ExternalSmallMinor,
    MarketingMajor,
    StoreMajor,
} from "@shopify/polaris-icons";
import { useLoopAuth } from "./user-provider";

function LoopTopBar({ toggleMobileNavigationActive, storeSetting, ...props }) {
    const { logout, user } = useLoopAuth();

    const [userMenuActive, setUserMenuActive] = useState(false);
    const toggleUserMenuActive = useCallback(
        () => setUserMenuActive((userMenuActive) => !userMenuActive),
        [],
    );

    const [isSecondaryMenuOpen, setIsSecondaryMenuOpen] = useState(false);
    const toggleIsSecondaryMenuOpen = useCallback(
        () =>
            setIsSecondaryMenuOpen(
                (isSecondaryMenuOpen) => !isSecondaryMenuOpen,
            ),
        [],
    );

    const userMenuMarkup = (
        <TopBar.UserMenu
            actions={[
                {
                    items: [
                        {
                            content: (
                                <Stack>
                                    <Icon source={StoreMajor} color="base" />{" "}
                                    <Text>{storeSetting?.name}</Text>
                                    <Icon
                                        source={ExternalSmallMinor}
                                        color="base"
                                    />
                                </Stack>
                            ),
                            onAction: () => {
                                window.open(
                                    `https://${storeSetting?.myshopify_domain}/`,
                                    "_blank",
                                );
                            },
                        },
                        {
                            content: (
                                <Stack>
                                    {" "}
                                    <Icon
                                        source={QuestionMarkMinor}
                                        color="base"
                                    />{" "}
                                    <div>Help Center</div>
                                    <Icon
                                        source={ExternalSmallMinor}
                                        color="base"
                                    />
                                </Stack>
                            ),
                            external: true,
                            onAction: () => {
                                window.open(
                                    "https://learn.loopwork.co/en/",
                                    "_blank",
                                );
                            },
                        },
                    ],
                },
                {
                    items: [],
                },
                {
                    items: [
                        {
                            content: (
                                <Stack>
                                    {" "}
                                    <Icon
                                        source={LogOutMinor}
                                        color="base"
                                    />{" "}
                                    <div>Logout</div>
                                </Stack>
                            ),
                            onAction: logout,
                        },
                    ],
                },
            ]}
            name={
                (user?.firstName || "") +
                    (user?.lastName ? ` ${user.lastName}` : "") || ""
            }
            detail={user?.email || ""}
            initials={`${user?.firstName ? user?.firstName[0] : ""}${user?.lastName ? user?.lastName[0] : ""}`}
            open={userMenuActive}
            onToggle={toggleUserMenuActive}
        />
    );

    const secondaryMenuMarkup = (
        <TopBar.Menu
            activatorContent={
                <HeadwayWidget account="xbPp5J">
                    <Icon source={MarketingMajor} color="base" />
                </HeadwayWidget>
            }
            open={isSecondaryMenuOpen}
            onOpen={toggleIsSecondaryMenuOpen}
            onClose={toggleIsSecondaryMenuOpen}
        />
    );

    return (
        <TopBar
            showNavigationToggle
            userMenu={userMenuMarkup}
            secondaryMenu={secondaryMenuMarkup}
            searchResultsVisible={null}
            searchField={null}
            searchResults={null}
            onSearchResultsDismiss={null}
            onNavigationToggle={toggleMobileNavigationActive}
        />
    );
}

export default LoopTopBar;
