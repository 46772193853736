export async function createSellingPlanGroup(axios, body) {
    const { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/sellingPlanGroups/create`,
        body,
    );
    return data;
}

export async function readSellingPlanGroups(axios) {
    const { data } = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/sellingPlanGroups/read_spg`,
    );
    return data;
}

export async function readSellingPlanGroupByID(axios, query) {
    const { data } = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/sellingPlanGroups/read_by_id`,
        {
            params: query,
        },
    );
    return data;
}

export async function updateSellingPlanGroupByID(axios, body) {
    const { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/sellingPlanGroups/update`,
        body,
    );
    return data;
}

export async function deleteSellingPlanGroupByID(axios, body) {
    const { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/sellingPlanGroups/delete_spg`,
        body,
    );
    return data;
}

export async function readProductsForSellingPlanGroup(axios, query) {
    const { data } = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/sellingPlanGroups/read_products`,
        {
            params: query,
        },
    );
    return data;
}

export async function addVariantsToSellingPlanGroupByID(axios, body) {
    const { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/sellingPlanGroups/add_variants`,
        body,
    );
    return data;
}

export async function removeVariantsToSellingPlanGroupByID(axios, body) {
    const { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/sellingPlanGroups/remove_variants`,
        body,
    );
    return data;
}

export async function createScriptTags(axios, shop, variables) {
    const { data } = await axios.post(
        `${process.env.HOST}/script_tags/create`,
        {
            shop: shop,
            variables: JSON.stringify(variables),
        },
    );
    return data;
}

export async function deleteScriptTags(axios, shop, variables) {
    const { data } = await axios.post(
        `${process.env.HOST}/script_tags/delete`,
        {
            shop: shop,
            variables: JSON.stringify(variables),
        },
    );
    return data;
}

export async function readScriptTags(axios, shop) {
    const { data } = await axios.get(`${process.env.HOST}/script_tags/read`, {
        params: {
            shop: shop,
        },
    });
    return data;
}

export async function readSubscriptionContractById(axios, id) {
    const { data } = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/subscriptionContract/read_by_shopify_id`,
        {
            params: {
                shopify_id: id,
            },
        },
    );
    return data;
}

export async function updateSubscriptionContractBilling(axios, body) {
    const { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/subscriptionContract/update_billing_details`,
        body,
    );
    return data;
}

export async function checkLastBillingAttemptData(axios, data) {
    let response = await axios.post(
        process.env.HOST + `/subscription_contract/last_billing_attempt`,
        {
            data,
        },
    );
    return response.data;
}

export async function getSubscriptionOrdersSchedule(axios, _data) {
    const { data } = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/subscriptionContract/ordersSchedule`,
        { params: _data },
    );
    return data;
}

export async function getSubscriptionOrdersHistory(axios, _data) {
    const { data } = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/subscriptionContract/ordersHistory`,
        { params: _data },
    );
    return data;
}

export async function getPrepaidCreditTransactions(axios, query) {
  const { data } = await axios.get(
    `${process.env.LOOP_BACKEND}/api/merchant/subscriptionContract/prepaidCreditTransactions`,
    { params: query },
  );
  return data;
}

export async function createPrepaidCreditTransaction(axios, body) {
  const { data } = await axios.post(
    `${process.env.LOOP_BACKEND}/api/merchant/subscriptionContract/prepaidCreditTransactions`,
    body,
  );

  return data;
}

export async function readSubscriptionOrdersHistory(axios, _data) {
    const { data } = await axios.get(
        `${process.env.HOST}/subscription_contract/orders_history`,
        { params: _data },
    );
    return data;
}

export async function readSubscriptionOrdersSchedule(axios, _data) {
    const { data } = await axios.get(
        `${process.env.HOST}/subscription_contract/orders_schedule`,
        { params: _data },
    );
    return data;
}

export async function sendCustomerPaymentMethodUpdateLink(
    axios,
    customerPaymentMethodId,
) {
    const { data } = await axios.post(
        process.env.HOST + `/subscription_contract/update_pm`,
        {
            customerPaymentMethodId: customerPaymentMethodId,
        },
    );
    return data;
}

export async function editPaymentMethod(axios, body) {
    const { data } = await axios.post(
        `${process.env.HOST}/subscription_contract/edit_pm`,
        body,
    );
    return data;
}

export async function chargeNow(axios, contractId) {
    const { data } = await axios.post(
        process.env.HOST + `/subscription_contract/charge_now`,
        {
            subscriptionContract_id: contractId,
        },
    );
    return data;
}

export async function chargeNowV2(axios, _data) {
    const { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/subscriptionContract/chargeNow`,
        _data,
    );
    return data;
}

export async function skipOrderV2(axios, body) {
    const { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/subscriptionContract/skipOrder`,
        body,
    );
    return data;
}
export async function unSkipOrder(axios, body) {
    const { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/subscriptionContract/unSkipOrder`,
        body,
    );
    return data;
}

export async function skipOrderNew(
    axios,
    contractId,
    order_id,
    notifyCustomer,
) {
    const { data } = await axios.post(
        process.env.HOST + `/subscription_contract/skip_order`,
        {
            subscriptionContract_id: contractId,
            order_id: order_id,
            notifyCustomer: notifyCustomer,
        },
    );
    return data;
}

export async function reactivateOrderNew(axios, contractId, order_id) {
    const { data } = await axios.post(
        process.env.HOST + `/subscription_contract/reactivate_order`,
        {
            subscriptionContract_id: contractId,
            order_id: order_id,
        },
    );
    return data;
}

export async function rescheduleOrder(axios, body) {
    const { data } = await axios.post(
        `${process.env.HOST}/subscription_contract/reschedule`,
        body,
    );
    return data;
}

export async function pauseSubscription(axios, contractId, notifyCustomer) {
    const { data } = await axios.post(
        process.env.HOST + `/subscription_contract/pause`,
        {
            subscriptionContract_id: contractId,
            notifyCustomer: notifyCustomer,
        },
    );
    return data;
}

export async function pauseContract(axios, body) {
    const { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/subscriptionContract/pauseContract`,
        body,
    );
    return data;
}

export async function rescheduleContractOrder(axios, body) {
    const { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/subscriptionContract/rescheduleContract`,
        body,
    );
    return data;
}

export async function resumeSubscription(axios, contractId, notifyCustomer) {
    const { data } = await axios.post(
        process.env.HOST + `/subscription_contract/resume`,
        {
            subscriptionContract_id: contractId,
            notifyCustomer: notifyCustomer,
        },
    );
    return data;
}

export async function resumeContract(axios, body) {
    const res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/subscriptionContract/resumeContract`,
        body,
    );
    return res.data;
}

export async function getPreferenceDetails(axios, preferenceKey, type) {
    const res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/preferences/preferenceDetails?preferenceKey=${preferenceKey}&type=${type}`,
    );
    return res.data;
}

export async function reactivateSubscription(
    axios,
    contractId,
    notifyCustomer,
) {
    const { data } = await axios.post(
        process.env.HOST + `/subscription_contract/reactivate`,
        {
            subscriptionContract_id: contractId,
            notifyCustomer: notifyCustomer,
        },
    );
    return data;
}

export async function cancelSubscription(axios, _data) {
    const { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/subscriptionContract/cancel`,
        _data,
    );
    return data;
}

export async function addLine(axios, body) {
    const { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/subscriptionContract/add_line`,
        body,
    );
    return data;
}

export async function updateLine(axios, body) {
    const { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/subscriptionContract/update_line`,
        body,
    );
    return data;
}

export async function removeLine(axios, body) {
    const { data } = await axios.post(
        `${process.env.HOST}/subscription_contract/remove_line`,
        body,
    );
    return data;
}

export async function removeLineV2(axios, body) {
    const { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/subscriptionContract/removeLine`,
        body,
    );
    return data;
}

export async function undoRemoveOnetime(axios, body) {
    const { data } = await axios.post(
        `${process.env.HOST}/subscription_contract/undo_remove_onetime`,
        body,
    );
    return data;
}

export async function updateDeliveryPrice(axios, body) {
    const { data } = await axios.post(
        `${process.env.HOST}/subscription_contract/update_delivery_price`,
        body,
    );
    return data;
}

export async function contractSaveCustomAttributes(axios, body) {
    const { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/subscriptionContract/update_custom_attributes`,
        body,
    );
    return data;
}

export async function lineItemSaveCustomAttributes(axios, body) {
    const { data } = await axios.put(
        `${process.env.LOOP_BACKEND}/api/merchant/subscriptionContract/updateLineItemAttributes`,
        body,
    );
    return data;
}

export async function updateShippingAddress(axios, body) {
    const { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/subscriptionContract/update_shipping`,
        body,
    );
    return data;
}

export async function subscriptionAddDiscountCode(axios, body) {
    const { data } = await axios.post(
        process.env.HOST + `/subscription_contract/add_discount_code`,
        body,
    );
    return data;
}

export async function subscriptionRemoveDiscountCode(axios, body) {
    const { data } = await axios.post(
        process.env.HOST + `/subscription_contract/remove_discount_code`,
        body,
    );
    return data;
}

export async function readSettings(axios, shopOrigin, category, type) {
    let res = await axios.get(process.env.HOST + `/settings/read_settings`, {
        params: {
            category: category,
            shop: shopOrigin,
            type: type,
        },
    });
    return res;
}

export async function readSettingsV2(axios, category, type) {
    // TODO: remove _shopOrigin
    let { data } = await axios.get(
        `${process.env.LOOP_BACKEND}/api/preferences/fetch_settings_v2`,
        {
            params: {
                category: category,
                type: type,
            },
        },
    );
    return data;
}

export async function readTranslations(axios, type) {
    let res = await axios.get(
        process.env.HOST + `/settings/translations/fetch`,
        {
            params: {
                type: type,
            },
        },
    );
    return res.data;
}

export async function updateTranslations(axios, variables) {
    let res = await axios.post(
        process.env.HOST + `/settings/translations/update`,
        variables,
    );
    return res;
}

export async function readCustomerPortalTextTranslation(axios) {
    let res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/translations/customerPortalTexts`,
    );
    return res.data;
}

export async function updateCustomerPortalTextTranslation(axios, variables) {
    let res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/translations/updateCustomerPortalText`,
        variables,
    );
    return res;
}

export async function updateWidgetTranslation(axios, variables) {
    let res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/translations/updateWidgetTranslation`,
        variables,
    );
    return res;
}

export async function readTranslationsV2(axios, type) {
    let res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/translations/fetch_v2`,
        {
            params: {
                type: type,
            },
        },
    );
    return res.data;
}

export async function readTranslationsForCategoryV2(axios, type, categoryName) {
    let res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/translations/fetch_category_v2`,
        {
            params: {
                type: type,
                categoryName: categoryName,
            },
        },
    );
    return res.data;
}

export async function updateTranslationsV2(axios, variables) {
    let res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/translations/update_v2`,
        variables,
    );
    return res;
}

export async function updateCancellationFlowTranslations(axios, variables) {
    let res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/translations/updateCancellationFlowTranslations`,
        variables,
    );
    return res;
}

export async function readCustomWidget(axios, type) {
    let res = await axios.get(
        process.env.HOST + `/settings/custom-widget/fetch`,
        {
            params: {
                type: type,
            },
        },
    );
    return res.data;
}

export async function readCustomWidget2(axios, type) {
    let res = await axios.post(
        process.env.HOST + `/install/getCustomWidgetFilesFromLocal`,
        type,
    );
    return res.data;
}

export async function updateSettingsV2(axios, variables) {
    let res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/preferences/update_settings_v2`,
        variables,
    );
    return res;
}

export async function updateSettings(axios, variables) {
    let res = await axios.post(
        process.env.HOST + `/settings/update_settings`,
        variables,
    );
    return res;
}

export async function updateCustomWidget(axios, variables) {
    let res = await axios.post(
        process.env.HOST + `/settings/custom-widget/update`,
        variables,
    );
    return res.data;
}

export async function freeShippingDelivery(axios, variables) {
    try {
        const _endpoint = `${process.env.LOOP_BACKEND}/api/merchant/shippingProfiles/shippingData`;
        const _response = await axios.get(_endpoint);
        return _response?.data;
    } catch (e) {
        console.log("############## freeShippingDelivery ##############");
        console.log(e);
        console.log("################### freeShippingDelivery ###############");
        return { error: true };
    }
}

export async function fetchCarrierServices(axios, variables) {
    try {
        const _endpoint = `${process.env.LOOP_BACKEND}/api/merchant/shippingProfiles/carrierServices`;
        const _response = await axios.get(_endpoint);
        return _response?.data;
    } catch (e) {
        console.log("############## fetchCarrierServices ##############");
        console.log(e);
        console.log("################### fetchCarrierServices ###############");
        return { error: true };
    }
}

export async function saveDeliveryProfileOptions(axios, data) {
    try {
        const _endpoint = `${process.env.LOOP_BACKEND}/api/merchant/shippingProfiles/create`;
        return await axios.post(_endpoint, data);
    } catch (e) {
        console.log(
            "################### saveDeliveryProfileOptions ##############",
        );
        console.log(e);
        console.log(
            "################### saveDeliveryProfileOptions ###############",
        );
        return { error: true };
    }
}
export async function removeDeliveryProfileOptions(axios, data) {
    try {
        const _endpoint = `${process.env.LOOP_BACKEND}/api/merchant/shippingProfiles/delete`;
        return await axios.post(_endpoint, data);
    } catch (e) {
        console.log(
            "################### removeDeliveryProfileOptions ##############",
        );
        console.log(e);
        console.log(
            "################### removeDeliveryProfileOptions ###############",
        );
        return { error: true };
    }
}

export async function rescheduleFulfillmentOrder(axios, body) {
    let { data } = await axios.post(
        `${process.env.HOST}/subscription_contract/fulfillment_reschedule`,
        body,
    );
    return data;
}

export async function readFulfillments(axios, variables) {
    let { data } = await axios.get(
        `${process.env.HOST}/subscription_contract/orders_fulfillments`,
        {
            params: variables,
        },
    );
    return data;
}

export async function readFulfillments1(axios, variables) {
    let { data } = await axios.get(
        `${process.env.HOST}/subscription_order/read_fulfillments`,
        {
            params: variables,
        },
    );
    return data;
}

export async function readScopes(axios, variables) {
    let res = await axios.get(process.env.HOST + `/settings/read_scopes`, {
        params: variables,
    });
    return res;
}

export async function isSubscriptionsEligible(axios, shop) {
    let res = await axios.get(
        process.env.HOST + `/settings/is_subscriptions_eligible`,
        {
            params: { shop: shop },
        },
    );
    return res;
}

export async function readPaypalStatus(axios, shop) {
    let res = await axios.get(
        process.env.HOST + `/settings/read_paypal_status`,
        {
            params: { shop: shop },
        },
    );
    return res;
}

export async function readCustomers(axios, params) {
    let res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/customers/all`,
        {
            params: params,
        },
    );
    return res;
}

export async function readCustomersShopify(axios, shop, query) {
    let res = await axios.get(process.env.HOST + `/customers/read_all`, {
        params: { shop: shop, query: query },
    });
    return res;
}

export async function readCustomerById(axios, shop, id) {
    let res = await axios.get(process.env.HOST + `/customers/read_by_id`, {
        params: { shop: shop, id: id },
    });
    return res;
}

export async function readProducts(axios, query) {
    let res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/store/products/read_all_v2`,
        {
            params: query,
        },
    );
    return res.data;
}

export async function readSPforProduct(axios, query) {
    let { data } = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/store/products/discount_for_variant_spg`,
        {
            params: query,
        },
    );
    return data;
}

export async function readProductsSyncStatus(axios, query) {
    let res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/store/products/sync_status_v2`,
        {
            params: query,
        },
    );
    return res.data;
}

export async function readSnippetVersion(axios, shop) {
    let res = await axios.get(process.env.HOST + `/store/snippet_version`, {
        params: { domain: shop },
    });
    return res;
}

export async function createSubscription(axios, data) {
    let res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/subscriptionContract/create`,
        data,
    );
    return res;
}

export async function updateAdminNotificationEmail(axios, data) {
    let res = await axios.post(
        process.env.HOST + `/store/update-admin-notification-email`,
        data,
    );
    return res.data;
}

export async function updateReportingNotificationEmail(axios, data) {
    let res = await axios.post(
        process.env.HOST + `/store/update-admin-reporting-email`,
        data,
    );
    return res.data;
}

export async function fetchWidgetStatus(axios, data) {
    let res = await axios.post(process.env.HOST + `/store/widget-status`, data);
    return res.data;
}

export async function updateCustomer(axios, shop, variables) {
    let res = await axios.post(process.env.HOST + `/customers/update`, {
        shop: shop,
        variables: variables,
    });
    return res.data;
}

export async function createCustomer(axios, shop, variables) {
    let res = await axios.post(process.env.HOST + `/customers/create`, {
        shop: shop,
        variables: variables,
    });
    return res.data;
}

export async function updateShippingForAllContracts(axios, shopOrigin) {
    let res = await axios.post(
        `${process.env.HOST}/settings/shipping/update_prices`,
        { myshopify_domain: shopOrigin },
    );
    return res.data;
}

export async function updatePricesForAllContracts(axios, shopOrigin) {
    let res = await axios.post(
        `${process.env.HOST}/settings/product/update_prices`,
        { myshopify_domain: shopOrigin },
    );
    return res.data;
}

export async function getAllFeatures(axios) {
    let res = await axios.get(
        process.env.HOST + `/settings/billing/all_features`,
    );
    return res.data;
}

export async function getIntegrations(axios, shop) {
    let res = await axios.get(
        process.env.HOST + `/integrations/get_all_for_store`,
        {
            params: { myshopify_domain: shop },
        },
    );
    return res.data;
}

export async function getIntegrationDetails(axios, shop, identifier) {
    let res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/integrations/get_integration_for_store`,
        {
            params: { identifier: identifier },
        },
    );
    return res.data;
}

export async function connectIntegration(axios, shop, data) {
    let res = await axios.post(
        process.env.HOST + `/integrations/connect_integration`,
        { myshopify_domain: shop, ...data },
    );
    return res.data;
}
export async function disconnectIntegration(axios, shop, data) {
    let res = await axios.post(
        process.env.HOST + `/integrations/disconnect_integration`,
        { myshopify_domain: shop, ...data },
    );
    return res.data;
}

export async function connectIntegrationV2(axios, body) {
    let res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/integrations/connect_integration_v2`,
        body,
    );
    return res.data;
}
export async function disconnectIntegrationV2(axios, body) {
    let res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/integrations/disconnect_integration_v2`,
        body,
    );
    return res.data;
}

export async function getStoreSettings(axios, shop) {
    let res = await axios.get(process.env.HOST + `/store/settings`, {
        params: { myshopify_domain: shop },
    });
    return res.data;
}

export async function getBillingPlanForStore(axios) {
    const _res = await axios.post(
        `${process.env.HOST}/settings/fetch_billing`,
        {},
    );
    return _res?.data;
}

export async function fetchUpcomingPayment(axios, data) {
    let res = await axios.post(
        process.env.HOST + `/dunning/upcoming-payment`,
        data,
    );
    return res.data;
}

export async function fetchHighRiskSubscriptions(axios, params) {
    let res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/cancellation/highRiskSubscriptions`,
        params,
    );
    return res.data;
}

export async function exportHighRiskSubscriptions(axios, params) {
    let res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/cancellation/exportHighRiskSubscriptions`,
        params,
    );
    return res.data;
}

export async function fetchCancelledSubscriptions(axios, params) {
    let res = await axios.post(
        `${process.env.HOST}/cancellation/cancelled-subscriptions`,
        params,
    );
    return res.data;
}

export async function exportCancelledSubscriptions(axios, params) {
    let res = await axios.post(
        `${process.env.HOST}/cancellation/export-cancelled-subscriptions`,
        params,
    );
    return res.data;
}

export async function fetchCancellationFlowsStatsOpportunities(axios, params) {
    let res = await axios.post(
        `${process.env.HOST}/cancellation/cancellation-flows-opportunities`,
        params,
    );
    return res.data;
}
export async function fetchCancellationFlowsStatsChurnOverview(axios, params) {
    let res = await axios.post(
        `${process.env.HOST}/cancellation/cancellation-flows-churn-overview`,
        params,
    );
    return res.data;
}

export async function fetchExpiredPaymentActiveSubscriptions(axios, params) {
    const _res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/cancellation/expiredPaymentActiveSubscriptions`,
        params,
    );
    return _res.data;
}

export async function fetchSkippedPaymentPausedSubscriptions(axios, params) {
    const _res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/cancellation/skippedPaymentPausedSubscriptions`,
        params,
    );
    return _res.data;
}

export async function fetchActiveSubscriptionsWithLast2OrdersFailed(
    axios,
    params,
) {
    const _res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/cancellation/activeSubscriptionsWithLast2OrdersFailed`,
        params,
    );
    return _res.data;
}

export async function fetchChurnAndLossTrend(axios, params) {
    const _res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/cancellation/churnAndLossTrend`,
        params,
    );
    return _res.data;
}

export async function fetchSubscriberLoss(axios, params) {
    const _res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/cancellation/subscriberLossOverview`,
        params,
    );
    return _res.data;
}

export async function fetchChurnOverview(axios, params) {
    const _res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/cancellation/churnOverview`,
        params,
    );
    return _res.data;
}

export async function fetchAvgOrderBeforeCancellationOverview(axios, params) {
    const _res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/cancellation/avgOrdersBeforeCancellationOverview`,
        params,
    );
    return _res.data;
}

export async function fetchCancellationFlowsStatsOverview(axios, params) {
    let res = await axios.post(
        `${process.env.HOST}/cancellation/cancellation-flows-cancellation-overview`,
        params,
    );
    return res.data;
}
export async function fetchCancellationFlowsStatsPerformance(axios, params) {
    let res = await axios.post(
        `${process.env.HOST}/cancellation/cancellation-flows-cancellation-performance`,
        params,
    );
    return res.data;
}

export async function getAllReasonForCancellation(axios, params) {
    let res = await axios.post(
        `${process.env.HOST}/cancellation/all-cancellation-reason`,
        params,
    );
    return res.data;
}

export async function fetchCancellationFlowsStatsOrderWise(axios, params) {
    let res = await axios.post(
        `${process.env.HOST}/cancellation/cancellation-flows-cancellation-order-wise`,
        params,
    );
    return res.data;
}

export async function dunningStats(axios, data) {
    let res = await axios.post(process.env.HOST + `/dunning/stats`, data);
    return res.data;
}

export async function dunningTopStats(axios, data) {
    const _endpoint = `${process.env.LOOP_BACKEND}/api/merchant/dunning/topStats`;
    const _res = await axios.post(_endpoint, data);
    return _res.data;
}

export async function dunningPaymentFailures6Months(axios, data) {
    const _endpoint = `${process.env.LOOP_BACKEND}/api/merchant/dunning/paymentFailures6Months`;
    const _res = await axios.post(_endpoint, data);
    return _res.data;
}

export async function dunningPaymentSnapshots6Months(axios, data) {
    const _endpoint = `${process.env.LOOP_BACKEND}/api/merchant/dunning/paymentSnapshots6Months`;
    const _res = await axios.post(_endpoint, data);
    return _res.data;
}

export async function exportUpcomingPayment(axios, data) {
    let res = await axios.post(
        process.env.HOST + `/dunning/export-upcoming-payment`,
        data,
    );
    return res.data;
}

export const fetchTopStatsData = async (axios) => {
    try {
        const _endpoint = `${process.env.API_SERVICE}/analytics/topStats`;
        const _response = await axios.get(_endpoint);
        return _response?.data.data;
    } catch (e) {
        console.log("############## fetchTopStatsData ##############");
        console.log(e);
        console.log("################### fetchTopStatsData ###############");
    }
};

export async function fetchPotentialRevenue(axios) {
    try {
        const _endpoint = `${process.env.API_SERVICE}/analytics/revenueProjection`;
        const _response = await axios.get(_endpoint);
        return _response?.data.data;
    } catch (e) {
        console.log("############## fetchPotentialRevenue ##############");
        console.log(e);
        console.log(
            "################### fetchPotentialRevenue ###############",
        );
    }
}

export async function fetchRecentActivity(axios, data) {
    let res = await axios.post(
        process.env.HOST + `/analytics/subscriptions-count`,
        data,
    );
    return res.data;
}

export async function fetchSubscriptionsGraphData(axios, data) {
    const _endpoint = `${process.env.API_SERVICE}/analytics/subscriptionsGraph?startDate=${data.startDate}&endDate=${data.endDate}`;
    const _res = await axios.get(_endpoint);
    return _res.data?.data;
}

export async function fetchOrdersGraphData(axios, data) {
    const _endpoint = `${process.env.API_SERVICE}/analytics/ordersGraph?startDate=${data.startDate}&endDate=${data.endDate}`;
    const _res = await axios.get(_endpoint);
    return _res.data?.data;
}

export async function fetchPopupData(axios, data) {
    let res = await axios.post(
        process.env.HOST + `/settings/popup/fetch`,
        data,
    );
    return res.data;
}

export async function fetchForecastRevenueProductWise(axios, data) {
    try {
        const _endpoint = `${process.env.API_SERVICE}/analytics/inventoryForecast`;
        const _response = await axios.get(_endpoint);
        return _response?.data;
    } catch (e) {
        console.log("############## fetchInventoryForecast ##############");
        console.log(e);
        console.log(
            "################### fetchInventoryForecast ###############",
        );
    }
}

export async function readCancellationReasons(axios) {
    let res = await axios.get(process.env.HOST + `/settings/cancellation/read`);
    return res.data;
}

export async function readCancellationReasonById(axios, data) {
    let res = await axios.get(
        process.env.HOST + `/settings/cancellation/read_by_id`,
        { params: data },
    );
    return res.data;
}

export async function createCancellationReason(axios, data) {
    let res = await axios.post(
        process.env.HOST + `/settings/cancellation/create`,
        data,
    );
    return res.data;
}

export async function deleteCancellationReason(axios, data) {
    let res = await axios.post(
        process.env.HOST + `/settings/cancellation/delete`,
        data,
    );
    return res.data;
}

export async function editCancellationReason(axios, data) {
    let res = await axios.post(
        process.env.HOST + `/settings/cancellation/edit`,
        data,
    );
    return res.data;
}

export async function reorderCancellationReasons(axios, data) {
    let res = await axios.post(
        process.env.HOST + `/settings/cancellation/reorder`,
        data,
    );
    return res.data;
}

export async function readEmailTemplateByPrefKey(axios, data) {
    let res = await axios.get(
        process.env.HOST + `/settings/emails/templates/read_by_pref_key`,
        { params: data },
    );
    return res.data;
}

export async function saveEmailTemplate(axios, data) {
    let res = await axios.post(
        process.env.HOST + `/settings/emails/templates/save`,
        data,
    );
    return res.data;
}

export async function resetEmailTemplate(axios, data) {
    let res = await axios.post(
        process.env.HOST + `/settings/emails/templates/reset`,
        data,
    );
    return res.data;
}

export async function readEmailTemplateByPrefKeyV2(axios, data) {
    let res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/preferences/emails/templates/read_by_pref_key_v2`,
        {
            params: data,
        },
    );
    return res.data;
}

export async function saveEmailTemplateV2(axios, data) {
    let res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/preferences/emails/templates/save_v2`,
        data,
    );
    return res.data;
}

export async function resetEmailTemplateV2(axios, data) {
    let res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/preferences/emails/templates/reset_v2`,
        data,
    );
    return res.data;
}

export async function fetchOneClickLinks(axios, data) {
    let res = await axios.post(
        `${process.env.HOST}/settings/one-click-links`,
        data,
    );
    return res.data;
}

export async function fetchApiKeys(axios) {
    let res = await axios.get(`${process.env.HOST}/settings/api-keys/read`);
    return res.data;
}

export async function generateApiKey(axios, body) {
    let res = await axios.post(
        `${process.env.HOST}/settings/api-keys/generate`,
        body,
    );
    return res.data;
}

export async function updateApiKey(axios, body) {
    let res = await axios.post(
        `${process.env.HOST}/settings/api-keys/update`,
        body,
    );
    return res.data;
}

export async function revokeApiKey(axios, body) {
    let res = await axios.post(
        `${process.env.HOST}/settings/api-keys/revoke`,
        body,
    );
    return res.data;
}

export async function installOrderStatusScript(axios) {
    let res = await axios.post(
        `${process.env.HOST}/settings/order-status/install_script_tag`,
    );
    return res.data;
}

export async function uninstallOrderStatusScript(axios) {
    let res = await axios.post(
        `${process.env.HOST}/settings/order-status/uninstall_script_tag`,
    );
    return res.data;
}

export async function readAutomationPrerequisites(axios, params) {
    let res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/automations/read_prerequisites`,
        { params },
    );
    return res.data;
}

export async function createAutomation(axios, data) {
    let res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/automations/create`,
        data,
    );
    return res.data;
}

export async function readAllAutomations(axios, limit, offset) {
    let _query = "";
    if (limit) {
        _query = `?limit=${limit}&offset=${offset}`;
    }
    let res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/automations/read_all${_query}`,
    );
    return res.data;
}
export async function readAllAutomationsV2(axios, limit, offset) {
    let _query = "";
    if (limit) {
        _query = `?limit=${limit}&offset=${offset}`;
    }
    let res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/automations/readAll${_query}`,
    );
    return res.data;
}

export async function readAutomationById(axios, params) {
    let res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/automations/read_by_id`,
        {
            params,
        },
    );
    return res.data;
}

export async function editAutomation(axios, data) {
    let res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/automations/edit`,
        data,
    );
    return res.data;
}

export async function deleteAutomation(axios, data) {
    let res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/automations/delete`,
        data,
    );
    return res.data;
}

export async function readAutomationTemplates(axios, params) {
    let res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/automations/read_templates`,
        {
            params: params,
        },
    );
    return res.data;
}

export async function readAllSellingPlanGroups(axios) {
    let res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/sellingPlanGroups/read_spg_shopify`,
    );
    return res.data;
}

export async function readProductsBySellingPlanGroupId(axios, params) {
    let res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/sellingPlanGroups/read_product_by_spg_id`,
        {
            params: params,
        },
    );
    return res.data;
}

export async function uploadBoxImage(axios, data, config) {
    let res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/bundle/images`,
        data,
        config,
    );
    return res.data;
}

export async function createBundle(axios, data) {
    let res = await axios.post(`${process.env.API_SERVICE}/bundle`, data);
    return res.data;
}

export async function createBundleV2(axios, data) {
    let res = await axios.post(`${process.env.API_SERVICE}/bundle/v2`, data);
    return res.data;
}

export async function updateBundleV2(axios, data) {
    let res = await axios.post(
        `${process.env.API_SERVICE}/bundle/update/v2`,
        data,
    );
    return res.data;
}

export async function readAllBundles(axios, storeID) {
    let res = await axios.get(
        `${process.env.API_SERVICE}/bundle/list?storeId=${storeID}`,
    );
    return res.data?.data;
}

export async function readAllBundlesForMultilingual(axios, storeID) {
    let res = await axios.get(
        `${process.env.API_SERVICE}/bundle/multilingual/list?storeId=${storeID}`,
    );
    return res.data?.data;
}

export async function readBundleByID(axios, loopBundleId) {
    let res = await axios.get(
        `${process.env.API_SERVICE}/bundle/${loopBundleId}`,
    );
    return res.data?.data;
}

export async function readBundleByIDV3(axios, loopBundleId) {
    let res = await axios.get(
        `${process.env.API_SERVICE}/bundle/V3/${loopBundleId}`,
    );
    return res.data?.data;
}

export async function getPresetProductSPG(axios, loopBundleProductId) {
    let res = await axios.get(
        `${process.env.API_SERVICE}/bundle/getProductSPG?productId=${loopBundleProductId}`,
    );
    return res.data?.data;
}

export async function readBundleByIDV2(axios, loopBundleId) {
    let res = await axios.get(
        `${process.env.API_SERVICE}/bundle/v2/${loopBundleId}`,
    );
    return res.data?.data;
}

export async function checkIfPresetBundle(axios, loopBundleId) {
    let res = await axios.get(
        `${process.env.API_SERVICE}/bundle/checkIfPresetBundle?loopBundleId=${loopBundleId}`,
    );
    return res.data?.data?.isPresetBundle;
}

export async function deleteBundleByID(axios, data) {
    let res = await axios.post(
        `${process.env.LOOP_API_SERVICE}/bundle/delete`,
        data,
    );
    return res;
}

export async function updateBundleById(axios, data) {
    let res = await axios.post(
        `${process.env.API_SERVICE}/bundle/update`,
        data,
    );
    return res;
}

export async function readAllSellingPlans(axios) {
    let res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/sellingPlanGroups/read_all_selling_plans`,
    );
    return res.data;
}

export async function readSubscriptionSellingPlans(axios, contractId) {
    let res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/subscriptionContract/available_selling_plans_v2?contractId=${contractId}`,
    );
    return res;
}

export async function readVariantSellingPlans(axios, variantShopifyIds) {
    let { data } = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/sellingPlanGroups/available_selling_plans_for_variants?variantShopifyIds=${variantShopifyIds}`,
    );
    return data;
}

export async function getBundleStyles(axios, params) {
    let res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/branding/preferences`,
        {
            params: params,
        },
    );
    return res.data;
}

export async function updateBundleStyles(axios, data) {
    let res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/branding`,
        data,
    );
    return res;
}

export async function checkBundleSnippetInstallStatus(axios, themeId) {
    let res = await axios.get(
        `${process.env.HOST}/install/bundle/checkInstallStatus${
            themeId ? `?themeId=${themeId}` : ""
        } `,
    );
    return res.data;
}

export async function getAllThemes(axios) {
    let res = await axios.get(`${process.env.HOST}/install/shopify/themes`);
    return res;
}

export async function getstatusByTheme(axios) {
    let res = await axios.get(
        `${process.env.HOST}/install/bundle/statusByTheme`,
    );
    return res.data;
}

export async function installBundleScript(axios, data) {
    let res = await axios.post(
        `${process.env.HOST}/install/bundle/installScript`,
        data,
    );
    return res;
}

export async function installBundleCartScript(axios, data) {
    let res = await axios.post(
        `${process.env.HOST}/install/bundle/installCartSnippet`,
        data,
    );
    return res;
}

export async function bundleHasPermissions(axios, shopifyDomain) {
    let res = await axios.get(
        `${process.env.LOOP_API_SERVICE}/bundle/permissions?shopifyDomain=${shopifyDomain}`,
    );
    return res.data;
}

export async function getAllReports(axios) {
    let res = await axios.get(`${process.env.API_SERVICE}/reports`);
    return res.data;
}

export async function getReportFilterMetricsById(axios, reportId) {
    let res = await axios.get(`${process.env.API_SERVICE}/reports/${reportId}`);
    return res.data;
}

export async function getReportDataById(axios, data) {
    let res = axios.post(`${process.env.API_SERVICE}/reports/data`, data);
    return res;
}

export async function getReportFilterOptions(axios, reportKey, filterKey) {
    let res = await axios.get(
        `${process.env.API_SERVICE}/reports/${reportKey}/filters/${filterKey}`,
    );
    return res.data;
}

export async function exportReportDataById(axios, data) {
    let res = axios.post(`${process.env.API_SERVICE}/reports/export`, data);
    return res;
}

export async function readCancellationDiscount(axios, storeId) {
    let res = await axios.get(
        `${process.env.API_SERVICE}/discountIncentive?storeId=${storeId}`,
    );
    return res.data;
}

export async function createCancellationDiscountIncentive(axios, data) {
    let res = await axios.post(
        `${process.env.API_SERVICE}/discountIncentive`,
        data,
    );
    return res;
}

export async function updateCancellationDiscountIncentive(axios, data) {
    let res = await axios.put(
        `${process.env.API_SERVICE}/discountIncentive`,
        data,
    );
    return res;
}

export async function updateCancellationDiscountStatus(axios, data) {
    let res = await axios.patch(
        `${process.env.API_SERVICE}/discountIncentive/changeStatus`,
        data,
    );
    return res;
}

export async function getPauseFlowDetails(axios, storeId) {
    let res = await axios.get(
        `${process.env.API_SERVICE}/pauseFlow?storeId=${storeId}`,
    );
    return res.data;
}

export async function createPauseIntervalFlow(axios, data) {
    let res = await axios.post(`${process.env.API_SERVICE}/pauseFlow `, data);
    return res;
}

export async function updatePauseIntervalFlow(axios, data) {
    let res = await axios.put(`${process.env.API_SERVICE}/pauseFlow`, data);
    return res;
}

export async function pauseSubscriptionInterval(axios, data) {
    let res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/subscriptionContract/pause/v2`,
        data,
    );
    return res;
}

export async function readUpsellExclusionList(axios) {
    let { data } = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/upsell/read_excluded_products`,
    );
    return data;
}

export async function addUpsellExclusionList(axios, body) {
    let { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/upsell/add_excluded_products`,
        body,
    );
    return data;
}

export async function removeUpsellExclusionList(axios, body) {
    let { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/upsell/remove_excluded_products`,
        body,
    );
    return data;
}

export async function readUpsellPriorityList(axios) {
    let { data } = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/upsell/read_priority_products`,
    );
    return data;
}

export async function addUpsellPriorityList(axios, body) {
    let { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/upsell/add_priority_products`,
        body,
    );
    return data;
}

export async function removeUpsellPriorityList(axios, body) {
    let { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/upsell/remove_priority_products`,
        body,
    );
    return data;
}

export async function readSwapPriorityList(axios) {
    let { data } = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/cp_swap/read_priority_products`,
    );
    return data;
}

export async function addSwapPriorityList(axios, body) {
    let { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/cp_swap/add_priority_products`,
        body,
    );
    return data;
}

export async function removeSwapPriorityList(axios, body) {
    let { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/cp_swap/remove_priority_products`,
        body,
    );
    return data;
}

export async function readSwapExclusionList(axios) {
    let { data } = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/cp_swap/read_excluded_products`,
    );
    return data;
}

export async function addSwapExclusionList(axios, body) {
    let { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/cp_swap/add_excluded_products`,
        body,
    );
    return data;
}

export async function removeSwapExclusionList(axios, body) {
    let { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/cp_swap/remove_excluded_products`,
        body,
    );
    return data;
}

export async function readBASubscriptionConditions(axios, endPoint) {
    let res = await axios.get(
        `${process.env.BULK_ACTION}/api/merchant/bulkActions/${endPoint}`,
    );
    return res.data;
}

export async function integrationGetStatistics(axios, query) {
    let res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/integrations/metrics/get_statistics`,
        { params: query },
    );
    return res.data;
}

export async function getBASubscriptionCount(axios, body) {
    let { data } = await axios.post(
        `${process.env.BULK_ACTION}/api/merchant/bulkActions/entityCount`,
        body,
    );
    return data;
}

export async function createBulkAction(axios, body) {
    let { data } = await axios.post(
        `${process.env.BULK_ACTION}/api/merchant/bulkActions/create`,
        body,
    );
    return data;
}

export async function getAllBulkActions(axios, params) {
    let res = await axios.get(
        `${process.env.BULK_ACTION}/api/merchant/bulkActions/list${params}`,
    );
    return res.data;
}

export async function getBulkActionNameFilter(axios) {
    let res = await axios.get(
        `${process.env.BULK_ACTION}/api/merchant/bulkActions/bulkActionsNameList`,
    );
    return res.data;
}

export async function cancelBulkAction(axios, body) {
    let { data } = await axios.put(
        `${process.env.BULK_ACTION}/api/merchant/bulkActions/cancel`,
        body,
    );
    return data;
}

export async function checkIfBulkActionInProgress(axios, query) {
    let res = await axios.get(
        `${process.env.BULK_ACTION}/api/merchant/bulkActions/checkInProgress${
            query ? query : ""
        }`,
    );
    return res.data;
}

export async function checkIfPresetBAInProgress(axios, query) {
    let res = await axios.get(
        `${
            process.env.BULK_ACTION
        }/api/merchant/bulkActions/checkPresetBAInProgress${query ? query : ""}`,
    );
    return res.data;
}

export async function runBulkAction(axios, body) {
    let { data } = await axios.post(
        `${process.env.BULK_ACTION}/api/merchant/bulkActions/runBulkAction`,
        body,
    );
    return data;
}

export async function queueBulkAction(axios, bulkActionId) {
    return await axios.post(
        `${process.env.BULK_ACTION}/api/merchant/bulkActions/${bulkActionId}/queue`,
    );
}

export async function getBulkActionsProgress(axios, bulkActionId) {
    let res = await axios.get(
        `${process.env.BULK_ACTION}/api/merchant/bulkActions/progress?bulkActionId=${bulkActionId}`,
    );
    return res.data;
}
export async function getAnalyticsConfig(axios) {
    let response = await axios.get(
        `${process.env.API_SERVICE}/analytics/pageConfig`,
    );
    return response?.data;
}

export async function getAnalyticsSection(axios, key) {
    let response = await axios.get(
        `${process.env.API_SERVICE}/analytics/components${key}`,
    );
    return response.data;
}
export async function getAnalyticsSectionV2(axios, key) {
    let response = await axios.get(
        `${process.env.API_SERVICE}/analytics/${key}`,
    );
    return response.data;
}

export async function getAnalyticsComponentData(axios, endpoint, _dateParams) {
    let response = await axios.get(
        `${process.env.API_SERVICE}${endpoint}${_dateParams}`,
    );
    return response.data;
}

export async function getBulkActionConfig(axios) {
    let response = await axios.get(
        `${process.env.BULK_ACTION}/api/merchant/bulkActions/config`,
    );
    return response.data;
}

export async function getCurrentBAStatus(axios, bulkActionId) {
    let response = await axios.get(
        `${process.env.BULK_ACTION}/api/merchant/bulkActions/getStatus?bulkActionId=${bulkActionId}`,
    );
    return response.data;
}

export async function fetchAvailableQuickActions(axios) {
    const res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/quickActions/read_quick_actions`,
    );
    return res.data;
}

export async function createQuickAction(axios, body) {
    const res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/quickActions/create`,
        body,
    );
    return res.data;
}

export async function createQuickActionTranslations(axios, body) {
    const res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/quickActions/createTranslations`,
        body,
    );
    return res.data;
}

export async function fetchAllQuickActions(axios) {
    const res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/quickActions/read_all`,
    );
    return res.data;
}

export async function fetchAllQuickActionsForMultilingual(axios) {
    const res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/multilingual/quickActions/read_all`,
    );
    return res.data;
}

export async function fetchQuickAction(axios, id) {
    const res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/quickActions/read_by_id?id=${id}`,
    );
    return res.data;
}

export async function fetchQuickActionTranslationsById(axios, id) {
    const res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/quickActions/readTranslationsById?quickActionStoreId=${id}`,
    );
    return res.data;
}

export async function deleteQuickAction(axios, body) {
    const res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/quickActions/delete`,
        body,
    );
    return res.data;
}

export async function updateQuickAction(axios, body) {
    const res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/quickActions/update`,
        body,
    );
    return res.data;
}

export async function updateQuickActionTranslations(axios, body) {
    const res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/quickActions/updateTranslations`,
        body,
    );
    return res.data;
}

export async function getAutomationEventConditionConfig(axios) {
    const res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/automations/config`,
    );
    return res.data;
}

export async function getAutomationLogs(
    axios,
    limit,
    offset,
    nameParam,
    statusParam,
    contractId,
    dateFilter
) {
    const res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/automations/logs?limit=${limit}&offset=${offset}${nameParam}${statusParam}${contractId}${dateFilter}`,
    );
    return res.data;
}

export async function deleteBundleLine(axios, body) {
    const res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/bundle/delete`,
        body,
    );
    return res.data;
}

export async function deleteBundleLineOnce(axios, body) {
    const res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/bundle/deleteOnce`,
        body,
    );
    return res.data;
}

export async function getReportsExportData(axios, offset) {
    const res = await axios.get(
        `${process.env.API_SERVICE}/reports/exports?limit=30&offset=${offset}`,
    );
    return res.data;
}

export async function cancelReportExport(axios, body) {
    const res = await axios.post(
        `${process.env.API_SERVICE}/reports/export/cancel`,
        body,
    );
    return res.data;
}

export async function readSubscriptionActivityLogs(axios, _data) {
    const res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/subscriptionContract/read_activity_logs`,
        { params: _data },
    );
    return res.data;
}

export async function fetchAvailableLocales(axios) {
    const res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/multilingual/available_locales`,
    );
    return res.data;
}

export async function fetchTranslationCategories(axios, type) {
    const res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/multilingual/fetch_categories?type=${type}`,
    );
    return res.data;
}

export async function fetchTranslationsByCategoryId(
    axios,
    categoryId,
    localeStoreId,
) {
    const res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/multilingual/fetch_translations?categoryId=${categoryId}&localeStoreId=${localeStoreId}`,
    );
    return res.data;
}

export async function updateMultilingualTranslations(axios, body) {
    const res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/multilingual/update_translations`,
        body,
    );
    return res.data;
}

export async function readCancellationReasonsV2(axios) {
    let res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/preferences/cancellation/read_v2`,
    );
    return res.data;
}

export async function readActiveCancellationReasonsList(axios) {
    let res = await axios.get(`${process.env.LOOP_BACKEND}/api/preferences/cancellation/readActiveReasons`);
    return res.data;
}

export async function fetchTranslationsForlocalizedEntity(
    axios,
    localeStoreId,
    localizedEntityType,
    localizedEntityId,
) {
    let res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/multilingual/fetch_localized_entity_translations?localeStoreId=${localeStoreId}&localizedEntityType=${localizedEntityType}&localizedEntityId=${localizedEntityId}`,
    );
    return res.data;
}

export async function updateLocalizedEntityTranslations(axios, body) {
    let res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/multilingual/update_localized_entity_translations`,
        body,
    );
    return res.data;
}

export async function createBundleProducts(axios, data) {
    let res = await axios.post(
        `${process.env.API_SERVICE}/bundle/product/create`,
        data,
    );
    return res.data;
}

export async function createBundleDescription(axios, data) {
    console.log("data:", data);
    let res = await axios.post(
        `${process.env.API_SERVICE}/bundle/productDescription/create`,
        data,
    );
    return res.data;
}

export async function getBundleProductsPriority(axios, bundleId) {
    let res = await axios.get(
        `${process.env.API_SERVICE}/bundle/productPriority?bundleId=${bundleId}`,
    );
    return res.data;
}

export async function getBundleProductsDescription(axios, bundleId) {
    let res = await axios.get(
        `${process.env.API_SERVICE}/bundle/productDescription?bundleId=${bundleId}`,
    );
    return res.data;
}

export async function updateBundleProducts(axios, data) {
    let res = await axios.post(
        `${process.env.API_SERVICE}/bundle/product/update`,
        data,
    );
    return res.data;
}

export async function deleteBundleProducts(axios, data) {
    let res = await axios.post(
        `${process.env.API_SERVICE}/bundle/product/delete`,
        data,
    );
    return res.data;
}

export async function deleteBundleProductsPreset(axios, data) {
    let res = await axios.post(
        `${process.env.API_SERVICE}/bundle/delete/v2`,
        data,
    );
    return res.data;
}

export async function updateBundleDescription(axios, data) {
    let res = await axios.post(
        `${process.env.API_SERVICE}/bundle/productDescription/update`,
        data,
    );
    return res.data;
}

export async function fetchAllStoreLocations(axios) {
    let res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/store/available_locations`,
    );
    return res.data;
}

export async function addBundleTranslations(axios, data) {
    let res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/bundle/addTranslations`,
        data,
    );
    return res.data;
}

export async function getBundleTranslations(axios, bundleId) {
    const res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/bundle/bundleTranslationsByID?bundleId=${bundleId}`,
    );
    return res.data;
}

export async function updateBundleTranslations(axios, data) {
    const res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/bundle/updateTranslations`,
        data,
    );
    return res.data;
}

export async function getMultiLingualBundleTranslations(
    axios,
    bundleId,
    localeStoreId,
) {
    const res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/multilingual/bundleTranslations?bundleId=${bundleId}&localeStoreId=${localeStoreId}`,
    );
    return res.data;
}

export async function updateMultiLingualBundleTranslations(axios, body) {
    const res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/multilingual/updateBundleTranslations`,
        body,
    );
    return res.data;
}

export async function createBundleStyles(axios, body) {
    const res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/branding/bundle/create`,
        body,
    );
    return res.data;
}

export async function updateBundleStylesByGuid(axios, body) {
    const res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/branding/bundle/update`,
        body,
    );
    return res.data;
}

export async function getBundleStylesByGuid(
    axios,
    loopBundleId,
    shopifyDomain,
) {
    const res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/branding/bundle/fetch?loopBundleId=${loopBundleId}&shopifyDomain=${shopifyDomain}`,
    );
    return res.data;
}

export async function getIncentiveConfig(axios) {
    let res = await axios.get(`${process.env.API_SERVICE}/incentive/config`);
    return res.data;
}

export async function getStoreIncentivesList(axios, storeId) {
    let res = await axios.get(
        `${process.env.API_SERVICE}/incentive/list?storeId=${storeId}`,
    );
    return res.data;
}

export async function createIncentive(axios, data) {
    let res = await axios.post(`${process.env.API_SERVICE}/incentive`, data);
    return res.data;
}

export async function updateIncentive(axios, data) {
    let res = await axios.post(
        `${process.env.API_SERVICE}/incentive/update`,
        data,
    );
    return res.data;
}

export async function deleteIncentive(axios, data) {
    let res = await axios.post(
        `${process.env.API_SERVICE}/incentive/delete`,
        data,
    );
    return res.data;
}

export async function readIncentiveByGuid(axios, incentiveGuid) {
    let res = await axios.get(
        `${process.env.API_SERVICE}/incentive/${incentiveGuid}`,
    );
    return res.data?.data;
}

export async function uploadBASpecificEntityIds(axios, data, config) {
    console.log("data:", data);
    let res = await axios.post(
        `${process.env.BULK_ACTION}/api/merchant/bulkActions/validateEntityIds`,
        data,
        config,
    );
    return res.data;
}

export async function runSystemBulkAction(axios, body) {
    const endpointMaps = {
        orderSchedule: "merchant/orderSchedule",
        deliveryDateField: "merchant/deliveryDateField",
    };
    const actionKey = body.actionKey;
    if (!(actionKey in endpointMaps)) {
        throw new Error(`Invalid action key: ${actionKey}`);
    }
    const endpoint = endpointMaps[actionKey];
    let { data } = await axios.post(
        `${process.env.BULK_ACTION}/api/${endpoint}`,
        body,
    );
    return data;
}

export async function queueSystemBulkAction(axios, actionKey, context = null) {
    const response = await axios.post(
      `${process.env.BULK_ACTION}/api/merchant/bulkActions/createSystemBulkAction`,
      {
        action_key: actionKey,
        context,
      }
    );

    return response.status === 200 && response.data.success;
}


export async function getContractDeliveryMethod(axios, shopifyId) {
    const res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/subscriptionContract/deliveryMethod?shopifyId=${shopifyId}`,
    );
    return res.data;
}

export async function getAvailableCustomerAddresses(axios, shopifyId) {
    const res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/subscriptionContract/availableCustomerAddresses?customerShopifyId=${shopifyId}`,
    );
    return res.data;
}

export async function getCustomersAvailablePickupAddresses(axios, shopifyId) {
    const res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/subscriptionContract/availablePickupAddresses`,
    );
    return res.data;
}

export async function updateShippingDetails(axios, body) {
    const res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/subscriptionContract/updateShippingDetails`,
        body,
    );
    return res.data;
}

export async function updateLocalDelivery(axios, body) {
    const res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/subscriptionContract/updateLocalDeliveryDetails`,
        body,
    );
    return res.data;
}

export async function updateLocalDeliveryAddress(axios, body) {
    const res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/subscriptionContract/updateLocalDeliveryAddress`,
        body,
    );
    return res.data;
}

export async function updatePickupAddress(axios, body) {
    const res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/subscriptionContract/updatePickupDetails`,
        body,
    );
    return res.data;
}

export async function fetchAvailableDeliveryOptions(axios, body) {
    const res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/subscriptionContract/availableDeliveryOptions`,
        body,
    );
    return res.data;
}

export async function updateToShipping(axios, body) {
    const { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/subscriptionContract/updateToShipping`,
        body,
    );
    return data;
}

export async function updateToPickup(axios, body) {
    const { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/subscriptionContract/updateToPickup`,
        body,
    );
    return data;
}

export async function updateToLocalDelivery(axios, body) {
    const { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/subscriptionContract/updateToLocalDelivery`,
        body,
    );
    return data;
}

export async function checkIfStoreHasMultipleDeliveryOptions(axios) {
    const res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/store/multipleDeliveryOptions`,
    );
    return res.data;
}

export async function updateMultipleDeliveryOptions(axios, body) {
    const { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/store/updateMultipleDeliveryOptions`,
        body,
    );
    return data;
}

export async function readMappedCollectionsForSellingPlanGroup(axios, query) {
    const { data } = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/sellingPlanGroups/mapped_collections`,
        {
            params: query,
        },
    );
    return data;
}

export async function readUnMappedCollectionsForSellingPlanGroup(axios, query) {
    const { data } = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/sellingPlanGroups/mappable_collections`,
        {
            params: query,
        },
    );
    return data;
}

export async function readCollections(axios, query) {
    const { data } = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/collections`,
        {
            params: query,
        },
    );
    return data;
}

export async function readCollectionsSyncStatus(axios) {
    let res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/collections/seed_status`,
    );
    return res.data;
}

export async function unMapCollectionFromSellingPlanGroupByID(axios, body) {
    const { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/sellingPlanGroups/unmap_collections`,
        body,
    );
    return data;
}
export async function fetchDisabledZapietDatesDiscarded(axios, body) {
    const { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/zapiet/shippingDates`,
        body,
    );
    return data;
}

export async function fetchDisabledZapietDates(axios, body) {
    const { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/zapiet/datesConfig`,
        body,
    );
    return data;
}

export async function runUpdateCustomerTagsBulkAction(axios) {
    const { data } = await axios.post(
        `${process.env.BULK_ACTION}/api/merchant/customers/recalculateCustomerTags`,
    );
    return data;
}

export async function checkIfRecalculateTagsActionInProgress(axios) {
    const { data } = await axios.get(
        `${process.env.BULK_ACTION}/api/merchant/customers/checkIfBulkActionInProgress`,
    );
    return data;
}

export async function mapCollectionFromSellingPlanGroupByID(axios, body) {
    const { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/sellingPlanGroups/map_collections`,
        body,
    );
    return data;
}

export async function readCollectionProductsId(axios, query) {
    const { data } = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/collections/products`,
        {
            params: query,
        },
    );
    return data;
}

export async function readCollectionProductsSyncStatus(axios, collectionId) {
    let res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/collections/sync_status?collectionId=${collectionId}`,
    );
    return res.data;
}

export async function syncCollectionProducts(axios, body) {
    let res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/collections/sync`,
        body,
    );
    return res.data;
}

export async function seedCollections(axios) {
    const res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/collections/seed`,
    );
    return res.data;
}

export async function createBundleCustomAsset(axios, data) {
    const res = await axios.post(
        `${process.env.API_SERVICE}/bundle/custom-assets`,
        data,
    );
    return res.data;
}

export async function readBundleCustomAsset(url) {
    const response = await fetch(url, { cache: "no-cache" });
    const _data = await response.text();
    return _data;
}

export async function disablePreferences(axios) {
    const { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/zapiet/disablePreferences`,
    );
    return data;
}

export async function checkIfZapietIntegrated(axios) {
    const { data } = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/zapiet/isIntegrated`,
    );
    return data;
}

export async function getPaymentFailureErrorCode(axios) {
    const { data } = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/automations/paymentFailureErrorCode`,
    );
    return data;
}

export async function getPaymentFailureErrorMessage(axios) {
    const { data } = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/automations/paymentFailureErrorMessage`,
    );
    return data;
}

export async function readSwapPriorityCollectionsList(axios) {
    let { data } = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/cp_swap/priority_collections`,
    );
    return data;
}

export async function mapSwappbleCollection(axios, body) {
    const { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/cp_swap/add_priority_collections`,
        body,
    );
    return data;
}

export async function removeSwapCollectionsPriorityList(axios, body) {
    let { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/cp_swap/remove_priority_collections`,
        body,
    );
    return data;
}

export async function createStoreAutomaticDiscount(axios) {
    return await axios.post(
        `${process.env.API_SERVICE}/bundle/storeAutomaticDiscount`,
        {},
    );
}

export async function createSystemBulkAction(axios, body) {
    return await axios.post(
        `${process.env.LOOP_BACKEND}/api/presetBundle/preset/updateSubscriptionBulkAction`,
        body,
    );
}

export async function getOrderTagConfigByKey(axios, storeId, tagKey) {
    let res = await axios.get(
        `${process.env.API_SERVICE}/tagConfig/fetchByKey?storeId=${storeId}&entity=ORDER&tagKey=${tagKey}`,
    );
    return res.data?.data;
}

export async function updateDeliveryDateOrderTagSettings(axios, body) {
    let res = await axios.put(
        `${process.env.API_SERVICE}/tagConfig/orderSchedule/upsert`,
        body,
    );
    return res;
}

export async function readUpsellRecommendedCollectionsList(axios) {
    let { data } = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/upsell/recommended/collections`,
    );
    return data;
}

export async function addUpsellRecommendedCollections(axios, body) {
    const { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/upsell/recommended/collections`,
        body,
    );
    return data;
}

export async function removeUpsellRecommendedCollections(axios, body) {
    let { data } = await axios.delete(
        `${process.env.LOOP_BACKEND}/api/merchant/upsell/recommended/collections`,
        { data: body },
    );
    return data;
}

export async function editUpsellRecommendedCollections(axios, body) {
    const { data } = await axios.patch(
        `${process.env.LOOP_BACKEND}/api/merchant/upsell/recommended/collections`,
        body,
    );
    return data;
}

export async function readUpsellExcludedCollectionsList(axios) {
    let { data } = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/upsell/exclusion/collections`,
    );
    return data;
}

export async function mapUpsellExclusionCollection(axios, body) {
    const { data } = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/upsell/exclusion/collections`,
        body,
    );
    return data;
}

export async function removeUpsellExcludedCollections(axios, body) {
    let { data } = await axios.delete(
        `${process.env.LOOP_BACKEND}/api/merchant/upsell/exclusion/collections`,
        { data: body },
    );
    return data;
}

export async function getBundleTranslationsByKeys(axios, bundleId) {
    const res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/bundle/bundleTranslationsByTranslationKeys?bundleId=${bundleId}`,
    );
    return res.data;
}

export async function readCampaignActionsConfig(axios) {
    let res = await axios.get(
        `${process.env.API_SERVICE}/campaignActions/config`,
    );
    return res.data;
}

export async function getQuickActionSubscriptionCount(axios, body) {
    let res = await axios.post(
        `${process.env.API_SERVICE}/campaignActions/subscriptionCount`,
        body,
    );
    return res.data;
}

export async function getCampaignActionValidSubscriptionIdsCsv(axios, body) {
    let res = await axios.post(
        `${process.env.API_SERVICE}/campaignActions/validateSubscriptionIds`,
        body,
    );
    return res.data;
}

export async function createCampaignAction(axios, body) {
    let res = await axios.post(
        `${process.env.API_SERVICE}/campaignActions/create`,
        body,
    );
    return res.data;
}

export async function getCampaignActionList(axios, storeId, offset) {
    let res = await axios.get(
        `${process.env.API_SERVICE}/campaignActions/list?storeId=${storeId}&offset=${offset}`,
    );
    return res.data;
}

export async function reactivateContract(axios, body) {
    const res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/subscriptionContract/reactivateContract`,
        body,
    );
    return res.data;
}

export async function getAvailableAccountLogsActions(axios, myshopifyDomain) {
    const res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/accountLogs/availableActions`,
        {
            params: { myshopify_domain: myshopifyDomain },
        },
    );
    return res.data;
}

export async function getUsers(axios, myshopifyDomain) {
    const res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/users`,
        {
            params: { myshopify_domain: myshopifyDomain },
        },
    );
    return res.data;
}

export async function getAccountLogs(axios, query = {}, myshopifyDomain) {
    const res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/accountLogs`,
        {
            params: { ...query, myshopify_domain: myshopifyDomain },
        },
    );
    return res.data;
}

export async function addSubscriptionDiscount(axios, body) {
    const res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/subscriptionContract/addDiscount`,
        body,
    );
    return res.data;
}

export async function readAllBenefits(axios, storeId) {
    let res = await axios.get(
        `${process.env.API_SERVICE}/subscriptionBenefit/list?storeId=${storeId}`,
    );
    return res.data?.data;
}

export async function readAllBenefitsForMultilingual(axios, storeId) {
    let res = await axios.get(
        `${process.env.API_SERVICE}/subscriptionBenefit/multilingual/list?storeId=${storeId}`,
    );
    return res.data?.data;
}

export async function readBenefitByGuid(axios, subscriptionBenefitGuid) {
    let res = await axios.get(
        `${process.env.API_SERVICE}/subscriptionBenefit/${subscriptionBenefitGuid}`,
    );
    return res.data?.data;
}

export async function createBenefit(axios, payload) {
    let res = await axios.post(
        `${process.env.API_SERVICE}/subscriptionBenefit`,
        payload,
    );
    return res.data;
}

export async function deleteBenefit(axios, payload) {
    let res = await axios.post(
        `${process.env.API_SERVICE}/subscriptionBenefit/delete`,
        payload,
    );
    return res.data;
}

export async function updateBenefit(axios, payload) {
    let res = await axios.put(
        `${process.env.API_SERVICE}/subscriptionBenefit`,
        payload,
    );
    return res.data;
}

export async function fetchTranslationsForCancellationBenefits(
    axios,
    benefitId,
    localeStoreId,
) {
    const res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/multilingual/cancellationBenefit?benefitId=${benefitId}&localeStoreId=${localeStoreId}`,
    );
    return res.data;
}

export async function updateCancellationBenefitsTranslations(axios, body) {
    let res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/multilingual/updateCancellationBenefit`,
        body,
    );
    return res.data;
}

export async function readUpsellRecommendedBundlesList(axios) {
    let { data } = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/upsell/recommended/bundles`,
    );
    return data;
}

export async function readUpsellExcludedBundlesList(axios) {
    let { data } = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/upsell/exclusion/bundles`,
    );
    return data;
}

export async function readBundles(axios, query) {
    const { data } = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/upsell/bundles`,
        {
            params: query,
        },
    );
    return data;
}

export async function addRecommendedBundles(axios, body) {
    const res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/upsell/recommended/bundles`,
        body,
    );
    return res.data;
}

export async function addExcludedBundles(axios, body) {
    const res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/upsell/exclusion/bundles`,
        body,
    );
    return res.data;
}

export async function removeUpsellRecommendedBundles(axios, body) {
    const res = await axios.delete(
        `${process.env.LOOP_BACKEND}/api/merchant/upsell/recommended/bundles`,
        { data: body },
    );
    return res.data;
}

export async function removeUpsellExcludedBundles(axios, body) {
    const res = await axios.delete(
        `${process.env.LOOP_BACKEND}/api/merchant/upsell/exclusion/bundles`,
        { data: body },
    );
    return res.data;
}

export async function getUserTypes(axios) {
    const res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/users/userTypes`,
    );
    return res.data;
}

export async function getUserListData(axios, query) {
    const res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/users/list`,
        {
            params: query,
        },
    );
    return res.data;
}

export async function getUserPermission(axios, email) {
    const res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/users/permissions/${email}`,
    );
    return res.data;
}

export async function updateUserPermission(axios, email, permissions) {
    const res = await axios.put(
        `${process.env.LOOP_BACKEND}/api/merchant/users/permissions/${email}`,
        { permissions },
    );
    return res.data;
}

export async function updateUserType(axios, data) {
    const res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/users/updateType`,
        data,
    );
    return res.data;
}

export async function deleteUser(axios, email) {
    const res = await axios.delete(
        `${process.env.LOOP_BACKEND}/api/merchant/users/${email}`
    );
    return res.data;
}

export async function getCancellationReasonsList(axios) {
    let { data } = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/cancellationReason/list`,
    );
    return data;
}

export async function getCancellationReasonById(axios, cancellationReasonId) {
    let { data } = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/cancellationReason?cancellationReasonId=${cancellationReasonId}`,
    );
    return data;
}

export async function updateCancellationReasonPosition(axios, payload) {
    const res = await axios.put(
        `${process.env.LOOP_BACKEND}/api/merchant/cancellationReason/position`,
        payload,
    );
    return res.data;
}

export async function createCancellationReasonV2(axios, payload) {
    const res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/cancellationReason/create`,
        payload,
    );
    return res.data;
}

export async function updateCancellationReasonV2(axios, payload) {
    const res = await axios.put(
        `${process.env.LOOP_BACKEND}/api/merchant/cancellationReason/update`,
        payload,
    );
    return res.data;
}

export async function deleteCancellationReasonV2(axios, payload) {
    const res = await axios.put(
        `${process.env.LOOP_BACKEND}/api/merchant/cancellationReason/delete`,
        payload,
    );
    return res.data;
}

export async function getPrepaidV2FeatureSwitches(axios) {
    const res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/featureGate/featureSwitches`,
    );
    return res.data;
}

export async function getCampaignActionDataById(
    axios,
    campaignActionId,
    storeId,
) {
    let res = await axios.get(
        `${process.env.API_SERVICE}/campaignActions/readById?campaignActionId=${campaignActionId}&storeId=${storeId}`,
    );
    return res.data;
}

export async function readAutomationListForMultilingual(axios) {
    let res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/automations/readAll/multilingual`,
    );
    return res.data;
}

export async function getMultiLingualBundleProductsTranslations(
    axios,
    bundleId,
    localeStoreId,
) {
    const res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/multilingual/bundleProductsTranslations?bundleId=${bundleId}&localeStoreId=${localeStoreId}`,
    );
    return res.data;
}

export async function updateMultiLingualBundleProductsTranslations(
    axios,
    body,
) {
    const res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/multilingual/updateBundleProductsTranslations`,
        body,
    );
    return res.data;
}

export async function updateBundleExcludedProductVariants(axios, data) {
    let res = await axios.post(
        `${process.env.API_SERVICE}/bundle/excludedBundleProductVariant`,
        data,
    );
    return res.data;
}

export async function fetchBundleExcludedProductVariants(axios, bundleId) {
    let res = await axios.get(
        `${process.env.API_SERVICE}/bundle/excludedBundleProductVariant?bundleId=${bundleId}`,
    );
    return res.data;
}

export async function fetchAvailableFeaturesForSellingPlanGroups(
    axios,
    loopSellingPlanGroupId,
) {
    const response = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/sellingPlanGroups/availableFeatureConfig${loopSellingPlanGroupId ? `?loopSellingPlanGroupId=${loopSellingPlanGroupId}` : ""}`,
    );
    return response.data;
}

export async function getMultiLingualBundleCategoriesTranslations(
    axios,
    bundleId,
    localeStoreId,
) {
    const res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/multilingual/bundleCategoriesTranslations?bundleId=${bundleId}&localeStoreId=${localeStoreId}`,
    );
    return res.data;
}

export async function updateMultiLingualBundleCategoriesTranslations(
    axios,
    body,
) {
    const res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/multilingual/updateBundleCategoriesTranslations`,
        body,
    );
    return res.data;
}

export async function getMultiLingualBundleVariantsTranslations(
    axios,
    bundleId,
    localeStoreId,
) {
    const res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/multilingual/bundleVariantsTranslations?bundleId=${bundleId}&localeStoreId=${localeStoreId}`,
    );
    return res.data;
}

export async function updateMultiLingualBundleVariantsTranslations(
    axios,
    body,
) {
    const res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/multilingual/updateBundleVariantsTranslations`,
        body,
    );
    return res.data;
}

export async function getUnresolvedAlerts(axios) {
    const data = await axios.get(
      `${process.env.ALERT_CENTRE}/merchant/alerts/unresolved`
    );
    return data.data;
}

export async function getAlertInstanceData(axios, alertId) {
  const data = await axios.get(
    `${process.env.ALERT_CENTRE}/merchant/alerts/${alertId}`
  );
  return data.data;
}

export async function getAlertInstanceActionData(axios, alertId, query) {
  let res = await axios.get(
    `${process.env.ALERT_CENTRE}/merchant/alerts/${alertId}/data`,
    {
      params: query,
    }
  );
  return res.data;
}

export async function refreshAlertInstanceData(axios, alertId) {
  const { data } = await axios.post(
    `${process.env.ALERT_CENTRE}/merchant/alerts/${alertId}/refresh`
  );
  return data.data;
}

export async function getAlertTypesLogsFilter(axios) {
  let res = await axios.get(
    `${process.env.ALERT_CENTRE}/merchant/alerts/types`
  );
  return res.data;
}

export async function getAlertLogs(axios, query) {
  let res = await axios.get(
    `${process.env.ALERT_CENTRE}/merchant/alerts/logs`,
    {
      params: query,
    }
  );
  return res.data;
}

export async function generateAlertResolution(axios, alertId, body) {
  const res = await axios.post(
    `${process.env.ALERT_CENTRE}/merchant/alerts/${alertId}/startResolution`,
    body
  );
  return res;
}

export async function getAlertInstanceResolutionStatus(axios, alertId) {
    let res = await axios.post(
      `${process.env.ALERT_CENTRE}/merchant/alerts/${alertId}/resolutionStatus`
    );
    return res.data;
}

export async function createUpsellProfile(axios, body) {
    const res = await axios.post(
        `${process.env.API_SERVICE}/upsell/createProfile`,
        body
    );
    return res.data;
}

export async function updateUpsellProfile(axios, body) {
    const res = await axios.post(
        `${process.env.API_SERVICE}/upsell/update`,
        body
    );
    return res.data;
}

export async function getProfileConditions(axios) {
    const res = await axios.get(
        `${process.env.API_SERVICE}/upsell/profileConditions`,
    );
    return res.data;
}

export async function generalUpsellDetails(axios, storeId) {
    const res = await axios.get(
        `${process.env.API_SERVICE}/upsell/getGeneralUpsellDetails?storeId=${storeId}`,
    );
    return res.data.data;
}

export async function upsellList(axios, storeId) {
    const res = await axios.get(
        `${process.env.API_SERVICE}/upsell/list?storeId=${storeId}`,
    );
    return res.data;
}

export async function updatePriority(axios, body) {
    const res = await axios.post(
        `${process.env.API_SERVICE}/upsell/updatePriority`,
        body,
    );
    return res.data;
}

export async function specificProfileDetails(axios, id) {
    const res = await axios.get(
        `${process.env.API_SERVICE}/upsell/${id}`,
    );
    return res.data;
}

export async function deleteUpsellProfile(axios, id) {
    const res = await axios.post(
        `${process.env.API_SERVICE}/upsell/delete/${id}`,
    );
    return res.data;
}

export async function countriesData(axios) {
    const res = await axios.get(
        `${process.env.API_SERVICE}/upsell/getAllCountries`,
    );
    return res.data.data;
}

export async function updateItemDiscount(axios, body) {
    const res = await axios.post(
        `${process.env.API_SERVICE}/upsell/updateItemDiscount`,
        body
    );
    return res.data.data;
}


// Widget V2 starts

// create widget
export async function createWidgetApi(axios, body) {
    const res = await axios.post(process.env.WIDGET_ENGINE + `/widgets`, body);
    return res?.data;
}

// update widget
export async function updateWidgetApi(axios, id, body) {
    const res = await axios.put(process.env.WIDGET_ENGINE + `/widgets/${id}`, body);
    return res?.data;
}

// get all widgets
export async function getAllWidgetsApi(axios, type) {
    const res = await axios.get(process.env.WIDGET_ENGINE + `/widgets`);
    return res?.data;
}

// get single widget
export async function getWidgetByIdApi(axios, id) {
    const res = await axios.get(process.env.WIDGET_ENGINE + `/widgets/${id}`);
    return res?.data;
}

// get single widget themes mapping details
export async function getWidgetThemesMappingDetailsByIdApi(axios, id, type) {
    const res = await axios.get(process.env.WIDGET_ENGINE + `/widgets/${id}/themesMapping`);
    return res?.data;
}

// get preferences
export async function getWidgetPreferencesApi(axios, id) {
    const res = await axios.get(process.env.WIDGET_ENGINE + `/widgets/${id ? `${id}/` : ''}preferences`);
    return res?.data;
}

// update/create preferences
export async function updateWidgetPreferencesApi(axios, id, body) {
    const res = await axios.post(process.env.WIDGET_ENGINE + `/widgets/${id}/preferences`, body);
    return res?.data;
}

// get texts
export async function getWidgetTextsApi(axios, id) {
    const res = await axios.get(process.env.WIDGET_ENGINE + `/widgets/${id ? `/${id}/` : ''}texts`);
    return res?.data;
}

// update/create texts
export async function updateWidgetTextsApi(axios, id, body) {
    const res = await axios.post(process.env.WIDGET_ENGINE + `/widgets/${id}/texts`, body);
    return res?.data;
}

// get widget styles by id
export async function getWidgetStylesApi(axios, id) {
    const res = await axios.get(process.env.WIDGET_ENGINE + `/widgets/${id ? `/${id}/` : ''}styles`);
    return res?.data;
}

// update widget styles
export async function updateWidgetStylesApi(axios, id, body) {
    const res = await axios.post(process.env.WIDGET_ENGINE + `/widgets/${id}/styles`, body);
    return res?.data;
}

// update theme templates mapping
export async function updateWidgetThemesTemplatesApi(axios, id, body) {
    const res = await axios.post(process.env.WIDGET_ENGINE + `/widgets/${id}/themes/mapping`, body);
    return res?.data;
}

//get all themes
export async function getAllThemesApi(axios){
    const res = await axios.get(process.env.WIDGET_ENGINE + `/widgets/themes`);
    return res?.data;
}

// get template mapping for a theme
export async function getTemplateMappingForATheme(axios, themeId, type="PRODUCT"){
    const res = await axios.get(process.env.WIDGET_ENGINE + `/widgets/themes/${themeId}/mapping/${type}`);
    return res?.data;
}

export async function updateWidgetStatusOnStore(axios, body){
    const res = await axios.patch(process.env.WIDGET_ENGINE + `/widgets/togglePublish`, body);
    return res?.data;
}

export async function refreshStoreData(axios){
    const res = await axios.get(process.env.WIDGET_ENGINE + `/widgets/refreshStoreData`);
    return res?.data;
}

// Widget V2 ends

export async function getStoreData(axios){
    const res = await axios.get(process.env.LOOP_BACKEND + `/api/merchant/stores/primaryDetails`);
    return res.data;
}

export async function fetchIncentiveTranslations(axios, incentiveId, localeStoreId) {
    const res = await axios.get(
        `${process.env.LOOP_BACKEND}/api/merchant/multilingual/fetchIncentiveTranslations?incentiveId=${incentiveId}&localeStoreId=${localeStoreId}`,
    );
    return res.data;
}

export async function updateIncentiveTranslations(axios, body) {
    const res = await axios.post(
        `${process.env.LOOP_BACKEND}/api/merchant/multilingual/updateIncentiveTranslations`,
        body,
    );
    return res.data;
}

export async function getUpdatePMCountries(axios) {
    const res = await axios.get(`${process.env.API_SERVICE}/paymentFlow/countriesInfo`);
    return res.data;
}

export async function getUpdatePMBillingDetails(axios, body) {
    const res = await axios.post(`${process.env.API_SERVICE}/paymentFlow/details`, body);
    return res.data;
}

export async function getUpdatePMSessionId(body) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(body),
        redirect: "follow"
    };

    const response = await fetch("https://deposit.shopifycs.com/sessions", requestOptions);

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
}

export async function registerPMBillingDetails(axios, body) {
    const res = await axios.post(`${process.env.API_SERVICE}/paymentFlow/registerCustomerCard`, body);
    return res.data;
}

export async function updatePMBillingDetails(axios, body) {
    const res = await axios.post(`${process.env.API_SERVICE}/paymentFlow/updateCustomerCard`, body);
    return res.data;
}

export async function aiTranslation(axios, payload) {
    const res = await axios.post(
        `${process.env.API_SERVICE}/aiTranslate/translate`,
        payload,
    );
    return res.data.data;
}

export async function aiTranslateStatus(axios, myshopifyDomain, targetLanguage) {
    const res = await axios.get(
        `${process.env.API_SERVICE}/aiTranslate/translationStatus?myShopifyDomain=${myshopifyDomain}&targetLanguage=${targetLanguage}`,
    );
    return res.data.data;
}

export async function isNewPaymentFlowEnabled(axios) {
    const res = await axios.get(
        `${process.env.API_SERVICE}/paymentFlow/isAllowedForDomain`,
    );
    return res.data.data;
}