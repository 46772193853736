import { useRouter } from "next/router";
import { useState, createContext, useContext, useEffect } from "react";

import { useAxios } from "../hooks/useAxios";
import { permission, userPermissionAccessType } from "../utils/constants";

const LoopAuthContext = createContext(null);

export function LoopAuthProvider(props) {
    const router = useRouter();
    const [axios] = useAxios();

    const [unauthorized, setunauthorized] = useState(false);
    const [user, setuser] = useState(null);
    const [myshopifyDomain, setmyshopifyDomain] = useState(null);
    const [storeId, setstoreId] = useState(null);

    const [authCompleted, setauthCompleted] = useState(false);
    const [userPermissionData, setUserPermissionData] = useState([]);
    const [userPermissionsMapping, setUserPermissionsMapping] = useState({});

    const validateToken = async () => {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        if (params.loop_token) {
            localStorage.setItem("token", params.loop_token);
        }

        try {
            const { data } = await axios.get(
                `${process.env.AUTH_SERVICE_DOMAIN}/admin/user`,
            );
            setuser({
                firstName: data.data.firstName,
                lastName: data.data.lastName,
                email: data.data.email,
            });
            setUserPermissionData(data?.data?.permissions);
            const userPermissionAccessMapping = transformUserPermissionAccess(
                data?.data?.permissions,
            );
            setUserPermissionsMapping(userPermissionAccessMapping);
            setmyshopifyDomain(data.data.myshopifyDomain);
            setstoreId(data.data.storeId);
            setunauthorized(false);
            setauthCompleted(true);
        } catch (error) {
            setunauthorized(true);
            setauthCompleted(true);
        }
    };

    const logout = () => {
        localStorage.removeItem("token");
        router.push(`/login`);
    };

    const transformUserPermissionAccess = (permissionData) => {
        const result = {};
        for (const data of permissionData) {
            result[data.permissionKey] = data.access;
        }
        return result;
    };

    const validateUserPermission = (permissionKey, requiredPermission) => {
        if (!permissionKey || !requiredPermission) {
            return false;
        }
        const permissionAccess = userPermissionsMapping[permissionKey];
        if (!permissionAccess) {
            return false;
        }

        if (permissionAccess === userPermissionAccessType.NO_ACCESS) {
            return false;
        }

        if (requiredPermission === userPermissionAccessType.READ) {
            if (
                permissionAccess === userPermissionAccessType.READ ||
                permissionAccess === userPermissionAccessType.WRITE
            ) {
                return true;
            }
        } else if (requiredPermission === permissionAccess) {
            return true;
        }
        return false;
    };

    const hasModuleAccess = (moduleKey) => {
        switch (moduleKey) {
            case "analytics":
                return userPermissionData.find(
                    (ele) =>
                        ele.loopModuleKey === moduleKey &&
                        ele.access !== userPermissionAccessType.NO_ACCESS &&
                        ele.permissionKey !== permission.EXPORT_REPORTS,
                );
            default:
                return userPermissionData.find(
                    (ele) =>
                        ele.loopModuleKey === moduleKey &&
                        ele.access !== userPermissionAccessType.NO_ACCESS,
                );
        }
    };

    const hasFeatureAccess = (featureKey) => {
        return userPermissionData.find(
            (ele) =>
                ele.loopFeatureKey === featureKey &&
                ele.access !== userPermissionAccessType.NO_ACCESS,
        );
    };

    useEffect(() => {
        if (!authCompleted) {
            validateToken();
        }
    }, [router, authCompleted]);

    return (
        <LoopAuthContext.Provider
            value={{
                user,
                unauthorized,
                authCompleted,
                logout,
                myshopifyDomain,
                storeId,
                validateUserPermission,
                hasModuleAccess,
                hasFeatureAccess,
            }}
        >
            {props.children}
        </LoopAuthContext.Provider>
    );
}

export const useLoopAuth = () => useContext(LoopAuthContext);
