import Axios from "axios";

export function useAxios(axiosErrorHandle) {
  const instance = Axios.create();
  instance.interceptors.request.use(async (config) => {
    config.headers["authorization"] = `${localStorage?.getItem("token")}` || "";
    return config;
  });

  instance.interceptors.response.use(
    async (response) => {
      return response;
    },
    function (error) {
      if (error && error.toJSON().message === "Network Error") {
        if (axiosErrorHandle) {
          axiosErrorHandle({ isInternetIssue: true });
        }
      }

      if (error?.response?.status === 401) {
        if (axiosErrorHandle) {
          axiosErrorHandle({ authIssue: true });
        }
      }
      return Promise.reject(error);
    }
  );
  return [instance];
}
