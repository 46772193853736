import { create } from 'zustand';
import { useAxios } from '../hooks/useAxios';
import { getStoreData } from '../utils/queries';
import { handleAdminError } from '../utils/helpers';

const useStore = create((set, get) => {
  const [axios] = useAxios();

  return {
    storeData: {},
    storeLoading: false,
    setStoreData: async (shopOrigin) => {
      try {
        set({storeLoading: true});
        const response = await getStoreData(axios);
        set({ storeData: response, storeLoading: false });
      } catch (error) {
        handleAdminError(error, shopOrigin, "setStoreData", "storeProvider.js", null, {axios});
        set({storeLoading: true});
      }
    },
  };
});

export default useStore;
