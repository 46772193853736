import "../utils/app.css";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/plugins/code_view.min.css";

import { useState, useEffect, useCallback } from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import {
    AppProvider,
    Link,
    FooterHelp,
    Loading,
    Frame,
    Toast,
} from "@shopify/polaris";
import { useRouter } from "next/router";
import translations from "@shopify/polaris/locales/en.json";
import "@shopify/polaris/build/esm/styles.css";
import NextLink from "next/link";
import Head from "next/head";

import "../components/widget-preview/index.css";
import "../components/widgets/storefront/Widget.css";
import "../components/widgets/storefront/ToggleSwitch.css";
import { useAxios } from "../hooks/useAxios";

import { LoopAuthProvider, useLoopAuth } from "../components/user-provider";
import LoopNav from "../components/loop-nav.js";
import LoopTopBar from "../components/top-bar";
import Skeleton from "../components/skeleton";
import FreeTrial from "../components/freetrial";
import Disclaimer from "../components/disclaimer";
import PopUp from "../components/pop-up";

import {
    readScopes,
    readSnippetVersion,
    getStoreSettings,
    getAllFeatures,
    getBillingPlanForStore,
} from "../utils/queries";
import moment from "moment";
import { handleAdminError } from "../utils/helpers";
import { PrepaidFeaturesProvider } from "../components/PrepaidFeaturesProvider.js";
import { QueryClient, QueryClientProvider } from 'react-query'
import useStore from "../providers/storeProvider.js";

Bugsnag.start({
    apiKey: process.env.BUGSNAG_APIKEY,
    plugins: [new BugsnagPluginReact()],
});

function CustomLink({ children, url = "", external = false, ...rest }) {
    return (
        <NextLink href={url}>
            <a href={url} {...rest} target={external ? "_blank" : "_self"}>
                {children}
            </a>
        </NextLink>
    );
}

const App = (props) => {
    if (typeof document != "undefined") {
        document.title = "Loop Subscription";
    }
    const [axios] = useAxios();
    const { storeData, setStoreData} = useStore();

    const { Component = () => {}, pageProps = {} } = props;
    // const { Component, pageProps } = props;

    const router = useRouter();
    const [shopOrigin, setshopOrigin] = useState("");
    const { unauthorized, authCompleted, logout, user } = useLoopAuth();

    const [popupId, setpopupId] = useState(null);
    const [popupData, setpopupData] = useState({});
    const [popupTriggeredTime, setpopupTriggeredTime] = useState(null);
    const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
    let initStart = new Date(new Date().setDate(new Date().getDate() - 29));
    let _now = new Date();
    const [startDate, setstartDate] = useState(
        `${initStart.getMonth() + 1 < 10 ? "0" + (initStart.getMonth() + 1) : initStart.getMonth() + 1}/${
            initStart.getDate() < 10
                ? "0" + initStart.getDate()
                : initStart.getDate()
        }/${initStart.getFullYear()}`,
    );
    const [endDate, setendDate] = useState(
        `${_now.getMonth() + 1 < 10 ? "0" + (_now.getMonth() + 1) : _now.getMonth() + 1}/${
            _now.getDate() < 10 ? "0" + _now.getDate() : _now.getDate()
        }/${_now.getFullYear()}`,
    );

    const [currentPlan, setcurrentPlan] = useState(null);
    const [currentFeatureSet, setcurrentFeatureSet] = useState(new Set([]));
    const [featuresMap, setfeaturesMap] = useState({});
    const [_props, setProps] = useState({ ...props });
    const [isLoading, setisLoading] = useState(true);
    const lastReloadTime = "lastReloadTime";

    const toggleMobileNavigationActive = useCallback(
        () =>
            setMobileNavigationActive(
                (mobileNavigationActive) => !mobileNavigationActive,
            ),
        [],
    );

    const handleDateChange = ({ start, end }) => {
        setstartDate(start);
        setendDate(end);
    };

    const hasFeature = (key) => {
        if (key in featuresMap) {
            return !!currentPlan && currentFeatureSet.has(key);
        }
        return true;
    };

    const getMinPlanForFeature = (key) => {
        return featuresMap[key].min_plan;
    };

    //Internet Issue notifications
    const [activeInternetIssueToast, setactiveInternetIssueToast] =
        useState(false);
    const toggleInternetIssueToastActive = (value = true) => {
        setactiveInternetIssueToast(value);
    };

    const toastInternetIssueMarkup = activeInternetIssueToast ? (
        <Toast
            error={true}
            content="Unable to connect to server."
            onDismiss={() => {
                toggleInternetIssueToastActive(false);
            }}
        />
    ) : null;

    const axiosErrorHandle = (data, payload) => {
        if (data.isInternetIssue) {
            toggleInternetIssueToastActive();
        } else if (data.authIssue) {
            logout();
        }
    };

    const onLoad = async ({}) => {
        console.time("Auth");
        let [
            res_scopes,
            res_snippet_version,
            allfeaturesRes,
            storeSetting,
            billingDetails,
            storeRes,
        ] = await Promise.all([
            readScopes(axios, {}),
            readSnippetVersion(axios, ""),
            getAllFeatures(axios),
            getStoreSettings(axios, ""),
            getBillingPlanForStore(axios),
            setStoreData(shopOrigin),
        ]);
        const shopData = storeSetting?.shop_data || {};
        const _currentPlan = { data: billingDetails?.settings[0] };
        setshopOrigin(shopData?.myshopify_domain);
        return {
            shopData: { ...storeSetting, ...shopData },
            scopes: res_scopes?.data,
            snippetVersion: res_snippet_version?.data,
            allfeaturesRes,
            _currentPlan,
            storeSetting,
        };
    };

    const loadShopData = async () => {
        try {
            let response = await onLoad({ shopOrigin });
            if (!response.error) {
                let props = {
                    scopes: response?.scopes?.scopes,
                    shopData: response?.shopData,
                    snippet_version:
                        response?.snippetVersion.loop_snippet_version,
                    storeSetting: response?.storeSetting,
                };

                setcurrentFeatureSet(
                    new Set(response?._currentPlan.data.features),
                );
                setcurrentPlan(response?._currentPlan.data.active_plan);
                let _fMap = {};
                for (let feature of response?.allfeaturesRes.data) {
                    _fMap[feature.key] = feature;
                }
                setfeaturesMap(_fMap);

                setProps(props);
            }
        } catch (e) {
            handleAdminError(e, shopOrigin, "loadShopData", "App", ``, {
                params: shopOrigin,
            });
        }
    };

    const init = async () => {
        setisLoading(true);
        try {
            const _lastReloadValue = sessionStorage.getItem(lastReloadTime);
            if (moment().unix() - _lastReloadValue < 2) {
                router.push("/404");
            }
            sessionStorage.setItem(lastReloadTime, moment().unix());

            console.time("App Load");
            let response = await onLoad({ shopOrigin });
            console.timeEnd("App Load");
            if (!response.error) {
                let props = {
                    scopes: response?.scopes?.scopes,
                    shopData: response?.shopData,
                    snippet_version:
                        response?.snippetVersion.loop_snippet_version,
                    storeSetting: response?.storeSetting,
                };

                setcurrentFeatureSet(
                    new Set(response?._currentPlan.data.features),
                );
                setcurrentPlan(response?._currentPlan.data.active_plan);
                let _fMap = {};
                for (let feature of response?.allfeaturesRes.data) {
                    _fMap[feature.key] = feature;
                }
                setfeaturesMap(_fMap);

                setProps(props);
                let redirectTo = null;
                if (typeof window != "undefined") {
                    let url = new URL(window?.location?.href);
                    let href = url.href;
                    let origin = url.origin;
                    redirectTo = href.replace(origin, "");
                }
                if (redirectTo !== "/") {
                    router.push(redirectTo, redirectTo, { shallow: true });
                } else
                    router.push("/dashboard", "/dashboard", { shallow: true });
            } else {
                router.push(`/login${window.location.search}`);
            }
        } catch (e) {
            router.push("/404", "/404", { shallow: true });
            handleAdminError(e, shopOrigin, "init", "App", ``, {
                params: shopOrigin,
            });
        }
        setisLoading(false);
    };

    useEffect(() => {
        if (authCompleted) {
            if (unauthorized) {
                setisLoading(false);
                router.push(`/login${window.location.search}`);
            } else {
                init();
            }
        }
    }, [unauthorized, authCompleted]);

    useEffect(() => {
        if(_props.storeSetting && user) {
            window.CRISP_WEBSITE_ID="b288ec53-199b-4953-8e1f-083990f605cc";
            let s1 = document.createElement("script");
            s1.src = "https://client.crisp.chat/l.js";
            s1.async = 1;
            s1.onload = function(){}
            document.getElementsByTagName("head")[0].appendChild(s1);
            window.$crisp=[];

            window.$crisp.push(["safe", true])
            window.$crisp.push(["set", "user:email", [user.email]]);
            window.$crisp.push(["set", "session:data", ["myshopify_domain", _props.storeSetting.myshopify_domain]]);
            window.$crisp.push(["set", "session:data", ["store_email", _props.storeSetting.email]]); 
            window.$crisp.push(["set", "session:data", ["store_name", _props.storeSetting.name]]);
            window.$crisp.push(["set", "session:data", ["country", _props.storeSetting.country_name]]);
            window.$crisp.push(["set", "session:data", ["shop_owner", _props.storeSetting.shop_owner]]);
            window.$crisp.push(["set", "session:data", ["plan_name", _props.storeSetting.plan_name]]);
        }
    }, [_props, user])

    // Visitor recording integration
    useEffect(() => {
        if (shopOrigin && process.env.ENV === "production") {
            const scriptTag = document.createElement("script");
            scriptTag.type = "text/javascript";
            scriptTag.async = true;
            scriptTag.defer = true;
            scriptTag.text = `
        window._mfq = window._mfq || [];
        (function() {
          var mf = document.createElement("script");
          mf.type = "text/javascript"; mf.defer = true;
          mf.src = "https://cdn.mouseflow.com/projects/363f90bc-8541-4ff4-87c0-89a031c18e83.js";
          document.getElementsByTagName("head")[0].appendChild(mf);
        })();`;
            document.head.appendChild(scriptTag);
        }
    }, [shopOrigin]);

    if (router.pathname.startsWith("/login")) {
        return <Component {...pageProps} />;
    }

    if (isLoading) {
        return (
            <Frame
                topBar={
                    !unauthorized ? (
                        <LoopTopBar
                            {..._props}
                            toggleMobileNavigationActive={
                                toggleMobileNavigationActive
                            }
                        />
                    ) : null
                }
                logo={{
                    topBarSource:
                        "https://loop-subscription-assets.s3.amazonaws.com/loop-colored.svg",
                    contextualSaveBarSource:
                        "https://loop-subscription-assets.s3.amazonaws.com/loop-colored.svg",
                    url: process.env.HOST || "http://app.loopwork.co",
                    accessibilityLabel: "Loop Subscriptions",
                }}
                navigation={!unauthorized ? <LoopNav {..._props} /> : null}
            >
                <Loading />
                <Skeleton />
            </Frame>
        );
    }

    return (
        <Frame
            topBar={
                !unauthorized ? (
                    <LoopTopBar
                        {..._props}
                        toggleMobileNavigationActive={
                            toggleMobileNavigationActive
                        }
                    />
                ) : null
            }
            showMobileNavigation={mobileNavigationActive}
            onNavigationDismiss={toggleMobileNavigationActive}
            navigation={!unauthorized ? <LoopNav {..._props}/> : null}
            logo={{
                topBarSource:
                    "https://loop-subscription-assets.s3.amazonaws.com/loop-colored.svg",
                contextualSaveBarSource:
                    "https://loop-subscription-assets.s3.amazonaws.com/loop-colored.svg",
                url: "/dashboard",
                accessibilityLabel: "Loop Subscriptions",
            }}
        >
            {!unauthorized && !isLoading && shopOrigin ? (
                <Disclaimer shopOrigin={shopOrigin} />
            ) : null}
            {!unauthorized && !isLoading && shopOrigin ? (
                <FreeTrial shopOrigin={shopOrigin} />
            ) : null}
            <Component
                {..._props}
                shopOrigin={shopOrigin}
                startDate={startDate}
                endDate={endDate}
                handleDateChange={handleDateChange}
                hasFeature={hasFeature}
                getMinPlanForFeature={getMinPlanForFeature}
                axiosErrorHandle={axiosErrorHandle}
                loadShopData={() => {
                    loadShopData();
                }}
                handlePopupTrigger={(val) => {
                    setpopupId(val.triggerId);
                    setpopupData(val);
                    setpopupTriggeredTime(val.time);
                }}
            />
            <PopUp
                popupId={popupId}
                popupTriggeredTime={popupTriggeredTime}
                popupData={popupData}
                handlePopupTrigger={(val) => {
                    setpopupId(val.triggerId);
                    setpopupTriggeredTime(val.time);
                }}
            />
            {!unauthorized ? (
                <FooterHelp>
                    If you have any questions, please visit our{" "}
                    <Link url="https://learn.loopwork.co" external={true}>
                        support center
                    </Link>
                </FooterHelp>
            ) : null}
            {toastInternetIssueMarkup}
        </Frame>
    );
};

function AppContainer(props) {
    const queryClient = new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
    });
    return (
        <AppProvider i18n={translations} linkComponent={CustomLink}>
            <Head>
                <script src="https://cdnjs.cloudflare.com/ajax/libs/codemirror/5.3.0/codemirror.min.js" />
                <script src="https://cdnjs.cloudflare.com/ajax/libs/codemirror/5.3.0/mode/xml/xml.min.js" />
                <link
                    rel="stylesheet"
                    href="https://cdnjs.cloudflare.com/ajax/libs/codemirror/5.3.0/codemirror.min.css"
                ></link>
                <link
                    rel="shortcut icon"
                    href="https://cdn.shopify.com/s/files/applications/afedc08b31165df4c41d3d60cc6051dc_200x200.png?1634636851"
                />
            </Head>
            <QueryClientProvider client={queryClient}>
            <LoopAuthProvider>
                <PrepaidFeaturesProvider>
                    <App {...props} />
                </PrepaidFeaturesProvider>
            </LoopAuthProvider>
            </QueryClientProvider>
            <style>
                {`:root {
          --p-action-primary: #7D41FF;
          --p-action-primary-hovered: #713be6;
          --p-action-primary-pressed: #6434cc;
          --p-action-primary-depressed: #582eb3;
          --p-text-primary: #7D41FF;
          --p-text-primary-hovered: #713BE6;
          --p-text-primary-pressed: #6434CC;
      }`}
            </style>
        </AppProvider>
    );
}

export default AppContainer;
