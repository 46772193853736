import { Navigation } from "@shopify/polaris";
import { useRouter } from "next/router";
import { Icon, Stack } from "@shopify/polaris";
import NextLink from "next/link";

import {
  HomeMinor,
  RefreshMinor,
  ConnectMinor,
  AppsMinor,
  ReportMinor,
  OnlineStoreMinor,
  BehaviorMinor,
  SettingsMinor,
  QuestionMarkMinor,
  ExternalMinor,
  ArchiveMinor,
  ButtonMinor,
  DiamondAlertMinor,
} from "@shopify/polaris-icons";
import { useEffect, useState } from "react";
import { useLoopAuth } from "./user-provider";
import { permission, userPermissionAccessType } from "../utils/constants";
import { useAxios } from "../hooks/useAxios";
import { getUnresolvedAlerts } from "../utils/queries";
import useStore from "../providers/storeProvider";

function LoopNav(props) {
  const router = useRouter();
  const [axios] = useAxios();
  const { storeData} = useStore();
  const [selectedLabel, setselectedLabel] = useState("Home");
  const [selectedKey, setselectedKey] = useState("home");
  const [alertCount, setalertCount] = useState("");
  const { hasModuleAccess, hasFeatureAccess, validateUserPermission } = useLoopAuth();
  const storefrontWidgetVersion = storeData?.storefrontWidgetVersion ?? 1;

  const isRouteSelected = (key) => {
    return selectedKey == key;
  };

  const isModuleSelected = (key) => {
    return selectedKey.includes(key);
  };

  async function loadAlerts() {
    try {
      const data = await getUnresolvedAlerts(axios);
      if (data?.data?.length) {
        setalertCount((data?.data?.length).toString());
      }
    } catch (error) {
      console.log("error:", error);
    }
  }

  useEffect(() => {
    loadAlerts();
  }, []);

  useEffect(() => {
    if (router) {
      if (router.pathname) {
        let pathname = router.pathname;
        if (pathname === "/dashboard") {
          setselectedLabel("Home");
          document.title = "Home - Loop Subscriptions";
        } else if (
          pathname === "/subscriptions" ||
          pathname.startsWith("/subscriptions")
        ) {
          setselectedLabel("Subscriptions");
          document.title = "Subscriptions - Loop Subscriptions";
        } else if (pathname === "/alert-centre") {
          setselectedLabel("Alert centre");
          document.title = "Alert centre - Loop Subscriptions";
        } else if (pathname === "/selling-plans") {
          setselectedLabel("Selling Plans");
          document.title = "Selling Plans - Loop Subscriptions";
        } else if (pathname === "/integrations") {
          setselectedLabel("Integrations");
          document.title = "Integrations - Loop Subscriptions";
        } else if (pathname === "/settings/widget-design") {
          setselectedLabel("Subscription Widget");
          document.title = "Subscription Widget - Loop Subscriptions";
        } else if (pathname === "/integrations") {
          setselectedLabel("Integrations");
          document.title = "Integrations - Loop Subscriptions";
        } else if (pathname === "/settings") {
          setselectedLabel("Settings");
        } else if (pathname === "/widget-installation") {
          setselectedLabel("Installation");
          document.title = "Installation - Loop Subscriptions";
        } else if (pathname === "/flows") {
          setselectedLabel("Flows");
          document.title = "Flows - Loop Subscriptions";
        } else if (pathname === "/bulk-actions") {
          setselectedLabel("Bulk actions");
          document.title = "Bulk Actions - Loop Subscriptions";
        }  else if (pathname === "/quick-actions") {
          setselectedLabel("quick-actions");
          document.title = "Quick Actions - Loop Subscriptions";
        } else if (pathname === "/cancellation-settings") {
          setselectedLabel("Cancellation settings");
          document.title = "Settings - Loop Subscriptions";
        } else if (pathname === "/checkout-links") {
          setselectedLabel("Checkout links");
          document.title = "Checkout links  - Loop Subscriptions";
        }  else if (pathname.includes("/widgets/storefront/")) {
          setselectedLabel("Widgets");
          document.title = "Widgets";
        }
      }
    }
  }, [router]);

  useEffect(() => {
    if (router) {
      if (router.pathname) {
        let pathname = router.pathname;
        if (pathname === "/customer-portal/preferences") {
          setselectedKey("cp_preferences");
          setselectedLabel("");
        } else if (pathname === "/customer-portal/accessibility") {
          setselectedKey("cp_accessibility");
          setselectedLabel("");
        } else if (pathname.includes("/customer-portal/upsell")) {
          setselectedKey("cp_upsell");
          setselectedLabel("");
        } else if (pathname === "/customer-portal/swap") {
          setselectedKey("cp_swap");
          setselectedLabel("");
        } else if (pathname.includes("/customer-portal/themes")) {
          setselectedKey("cp_themes");
          setselectedLabel("");
        } else if (pathname.includes("/customer-portal/cancellation-flows")) {
          setselectedKey("cp_cancellation_flows");
          setselectedLabel("");
        } else if (pathname.includes("/customer-portal/texts")) {
          setselectedKey("cp_texts");
          setselectedLabel("");
        } else if (pathname == "/bundles/preferences") {
          setselectedKey("bundles_preferences");
          setselectedLabel("");
        } else if (pathname == "/bundles/snippets") {
          setselectedKey("bundles_snippets");
          setselectedLabel("");
        } else if (pathname.includes("/bundles")) {
          setselectedKey("bundles_index");
          setselectedLabel("");
        } else if (pathname.includes("/widgets/storefront/")) {
          setselectedKey("widgets");
        } else if (pathname === "/analytics") {
            setselectedKey("analytics_index");
            setselectedLabel("");
            document.title = "Dashboard - Loop Subscriptions";
        } else if (pathname.includes("/analytics/cancellation")) {
            setselectedKey("analytics_cancellation");
            setselectedLabel("");
            document.title = "Cancellation - Loop Subscriptions";
        } else if (pathname.includes("/analytics/orders")) {
            setselectedKey("analytics_orders");
            setselectedLabel("");
            document.title = "Order Analytics - Loop Subscriptions";
        } else if (pathname.includes("/analytics/reports")) {
            setselectedKey("analytics_reports");
            setselectedLabel("");
            document.title = "Reporting - Loop Subscriptions";
        } else if (pathname.includes("/analytics/dunning")) {
            setselectedKey("analytics_dunning");
            setselectedLabel("");
            document.title = "Dunning Management - Loop Subscriptions";
        } else if (pathname.includes("/analytics/product")) {
            setselectedKey("analytics_product");
            setselectedLabel("");
            document.title = "Product Analytics - Loop Subscriptions";
        } else if (pathname.includes("/analytics/order")) {
          setselectedKey("analytics_orders");
          setselectedLabel("");
          document.title = "Order Analytics - Loop Subscriptions";
      } else {
          setselectedKey("");
        }
      }
    }
  }, [router]);

  return (
    <Navigation location="/">
      <Navigation.Section
        fill
        items={[
          {
            url: "/dashboard",
            label: (
              <NextLink href="/dashboard">
                <div style={{ paddingBottom: "4px" }}>
                  <a
                    className={
                      "loop-navigation " +
                      (router.pathname == "/dashboard"
                        ? "active_url"
                        : "inactive_url")
                    }
                  >
                    Home
                  </a>
                </div>
              </NextLink>
            ),
            icon: HomeMinor,
            selected: "Home" === selectedLabel ? true : false,
          },
          {
            url: "/subscriptions",
            label: (
              <NextLink href="/subscriptions">
                <div style={{ paddingBottom: "4px" }}>
                  <a
                    className={
                      router.pathname == "/subscriptions"
                        ? "active_url"
                        : "inactive_url"
                    }
                  >
                    Subscriptions
                  </a>
                </div>
              </NextLink>
            ),
            icon: RefreshMinor,
            selected:
              "Subscriptions" === selectedLabel ||
              "Bulk actions" === selectedLabel ||
              "Selling Plans" === selectedLabel
                ? true
                : false,
            subNavigationItems: [
              {
                url: "/selling-plans",
                label: "Selling plans",
                selected: "Selling Plans" === selectedLabel ? true : false,
                disabled: !hasFeatureAccess("sellingPlan"),
              },
              {
                url: "/bulk-actions",
                label: "Bulk actions",
                selected: "Bulk actions" === selectedLabel ? true : false,
                disabled: !hasFeatureAccess("bulkActions"),
              },
            ],
          },
          {
            url: "/bundles",
            label: (
              <NextLink href="/bundles">
                <div style={{ paddingBottom: "4px" }}>
                  <a
                    className={
                      isModuleSelected("bundles")
                        ? "active_url"
                        : "inactive_url"
                    }
                  >
                    Bundles
                  </a>
                </div>
              </NextLink>
            ),
            icon: ArchiveMinor,
            selected: isModuleSelected("bundles"),
            disabled: !hasModuleAccess("bundles"),
            subNavigationItems: [
              {
                url: "/bundles/snippets",
                label: "Snippets",
                selected: isRouteSelected("bundles_snippets"),
                disabled: !hasFeatureAccess("bundleSnippets"),
              },
              {
                url: "/bundles/preferences",
                label: "Preferences",
                selected: isRouteSelected("bundles_preferences"),
                disabled: !hasFeatureAccess("bundlePreferences"),
              },
            ],
          },
          {
            url: "/analytics",
            label: (
              <NextLink href="/analytics">
                <div style={{ paddingBottom: "4px" }}>
                  <a
                    className={
                      router.pathname == "/analytics"
                        ? "active_url"
                        : "inactive_url"
                    }
                  >
                    Analytics
                  </a>
                </div>
              </NextLink>
            ),
            expanded: "Reporting" === selectedLabel ? true : false,
            icon: ReportMinor,
            disabled: !hasModuleAccess("analytics"),
            selected: isModuleSelected("analytics"),
            subNavigationItems: [
              {
                url: "/analytics/orders",
                label: "Orders",
                selected: isRouteSelected("analytics_orders"),
                disabled: !validateUserPermission(
                  permission.ORDER_ANALYTICS,
                  userPermissionAccessType.READ
                )
              },
              {
                url: "/analytics/product",
                label: "Products",
                selected: isRouteSelected("analytics_product"),
                disabled: !validateUserPermission(
                  permission.UPSELL_ANALYTICS,
                  userPermissionAccessType.READ,
                ),
              },
              {
                url: "/analytics/cancellation",
                label: "Cancellation",
                selected: isRouteSelected("analytics_cancellation"),
                disabled: !hasFeatureAccess("cancellationAnalytics"),
              },
              {
                url: "/analytics/dunning",
                label: "Dunning",
                selected: isRouteSelected("analytics_dunning"),
                disabled: !hasFeatureAccess("dunningAnalytics"),
              },
              {
                url: "/analytics/reports",
                label: "Reports",
                selected: isRouteSelected("analytics_reports"),
                disabled: !validateUserPermission(
                  permission.VIEW_REPORTS,
                  userPermissionAccessType.READ
                ),
              }
            ],
          },
          {
            url: "/flows",
            label: (
              <NextLink href="/flows">
                <div style={{ paddingBottom: "4px" }}>
                  <a
                    className={
                      router.pathname == "/flows"
                        ? "active_url"
                        : "inactive_url"
                    }
                  >
                    Flows
                  </a>
                </div>
              </NextLink>
            ),
            icon: ConnectMinor,
            selected: "Flows" === selectedLabel ? true : false,
            disabled: !hasModuleAccess("flowManagement"),
          },
          {
            url: "/quick-actions",
            label: (
              <NextLink href="/quick-actions">
                <div style={{ paddingBottom: "4px" }}>
                  <a
                    className={
                      router.pathname == "/quick-actions"
                        ? "active_url"
                        : "inactive_url"
                    }
                  >
                    Quick actions
                  </a>
                </div>
              </NextLink>
            ),
            icon: ButtonMinor,
            disabled: !hasModuleAccess("quickActions"),
            selected:
              "quick-actions" === selectedLabel ||
              "Checkout links" === selectedLabel
                ? true
                : false,
            subNavigationItems: [
              {
                url: "/checkout-links",
                label: "Checkout links",
                selected: "Checkout links" === selectedLabel ? true : false,
                disabled: !hasFeatureAccess("checkoutLinks"),
              },
            ],
          },
          {
            url: "/integrations",
            label: (
              <NextLink href="/integrations">
                <div style={{ paddingBottom: "4px" }}>
                  <a
                    className={
                      router.pathname == "/integrations"
                        ? "active_url"
                        : "inactive_url"
                    }
                  >
                    Integrations
                  </a>
                </div>
              </NextLink>
            ),
            icon: AppsMinor,
            selected: "Integrations" === selectedLabel ? true : false,
            disabled: !hasModuleAccess("integrations"),
          },
          {
            url: "/customer-portal",
            label: (
              <NextLink href="/customer-portal">
                <div style={{ paddingBottom: "4px" }}>
                  <a
                    className={
                      router.pathname == "/customer-portal"
                        ? "active_url"
                        : "inactive_url"
                    }
                  >
                    Customer portal
                  </a>
                </div>
              </NextLink>
            ),
            icon: OnlineStoreMinor,
            selected: isModuleSelected("cp"),
            subNavigationItems: [
              {
                url: "/customer-portal/preferences",
                label: "Preferences",
                selected: isRouteSelected("cp_preferences"),
                disabled: !hasFeatureAccess("preferences"),
              },
              {
                url: "/customer-portal/accessibility",
                label: "Accessibility",
                selected: isRouteSelected("cp_accessibility"),
                disabled: !hasFeatureAccess("accessibility"),
              },
              {
                url: "/customer-portal/upsell",
                label: "Upsell",
                selected: isRouteSelected("cp_upsell"),
                disabled: !hasFeatureAccess("upsell"),
              },
              {
                url: "/customer-portal/swap",
                label: "Swap",
                selected: isRouteSelected("cp_swap"),
                disabled: !hasFeatureAccess("productSwap"),
              },
              {
                url: "/customer-portal/cancellation-flows",
                label: "Cancellation flows",
                selected: isRouteSelected("cp_cancellation_flows"),
                disabled: !hasFeatureAccess("cancellationFlows"),
              },
              {
                url: "/customer-portal/texts",
                label: "Texts",
                selected: isRouteSelected("cp_texts"),
                disabled: !hasFeatureAccess("customerPortalTexts"),
              },
              {
                url: "/customer-portal/themes",
                label: "Themes",
                selected: isRouteSelected("cp_themes"),
                disabled: !hasFeatureAccess("themes"),
              },
            ],
            disabled: !hasModuleAccess("customerPortal"),
          },
          storefrontWidgetVersion === 1 ? {
            url: "/settings/widget-design",
            label: (
              <NextLink href="/settings/widget-design">
                <div style={{ paddingBottom: "4px" }}>
                  <a
                    className={
                      router.pathname == "/settings/widget-design"
                        ? "active_url"
                        : "inactive_url"
                    }
                  >
                    Subscription widget
                  </a>
                </div>
              </NextLink>
            ),
            expanded: "Installation" === selectedLabel ? true : false,
            icon: BehaviorMinor,
            selected:
              "Subscription Widget" === selectedLabel ||
              "Installation" === selectedLabel
                ? true
                : false,
            subNavigationItems: [
              {
                url: "/widget-installation",
                label: "Installation",
                selected: "Installation" === selectedLabel ? true : false,
                disabled: !hasFeatureAccess(
                  "widgetConfigurationAndInstallation"
                ),
              },
            ],
            disabled: !hasModuleAccess("subscriptionWidget"),
          } :
          {
            url: "/widgets/storefront/library",
            label: (
              <NextLink href="/widgets/storefront/library">
                <div style={{ paddingBottom: "4px" }}>
                  <a
                    className={
                      router.pathname.includes("/widgets/storefront/")
                        ? "active_url"
                        : "inactive_url"
                    }
                  >
                    Widgets
                  </a>
                </div>
              </NextLink>
            ),
            icon: BehaviorMinor,
            selected: selectedLabel === "Widgets" ? true : false,
            subNavigationItems: [],
            disabled: !hasModuleAccess("subscriptionWidget")
          },
        ]}
        rollup={{
          after: 12,
          view: "view",
          hide: "hide",
          activePath: "/",
        }}
      />
      <Navigation.Section
        items={[
          {
            url: "/alert-centre",
            label: (
              <NextLink href="/alert-centre">
                <a
                  className={
                    router.pathname == "/alert-centre"
                    ? "active_url"
                    : "inactive_url"
                  }
                  >
                  Alert center
                </a>
              </NextLink>
            ),
            icon: DiamondAlertMinor,
            selected: "Alert centre" === selectedLabel ? true : false,
            badge: alertCount ? alertCount : null,
            disabled: !hasModuleAccess('alertCentre')
          },
          {
            url: "/settings",
            label: (
              <NextLink href="/settings">
                <a
                  className={
                    "loop-navigation " +
                    (router.pathname == "/settings"
                      ? "active_url"
                      : "inactive_url")
                  }
                >
                  Settings
                </a>
              </NextLink>
            ),
            icon: SettingsMinor,
            selected: "Settings" === selectedLabel ? true : false,
            disabled: !hasModuleAccess("settings"),
          },
        ]}
      />
      <Navigation.Section
        items={[
          {
            onClick: () => {
              let link = "https://learn.loopwork.co/en/";
              window.open(link, "_blank");
            },
            url: "",
            label: (
              <Stack>
                <span>Help center</span>{" "}
                <Icon source={ExternalMinor} color="base" />
              </Stack>
            ),
            icon: QuestionMarkMinor,
            external: true,
            selected: false,
          },
        ]}
        action={{
          onClick: () => {},
        }}
        separator
      />
    </Navigation>
  );
}

export default LoopNav;
