import React, { createContext, useState, useEffect, useContext } from 'react';
import { useAxios } from "../hooks/useAxios";
import { FEATURE_SWITCHES } from '../utils/constants';

const FeaturesContext = createContext();

export const PrepaidFeaturesProvider = ({ children }) => {
    const [featureSwitches, setFeatureSwitches] = useState([]);
    const [axios] = useAxios();

    useEffect(() => {
        const getPrepaidV2FeatureSwitches = async () => {
            try {
                const res = await axios.get(`${process.env.LOOP_BACKEND}/api/merchant/featureGate/featureSwitches`);
                setFeatureSwitches(res.data.data.featureSwitches);
            } catch (error) {
                console.error('Failed to fetch feature switches', error);
            }
        };

        getPrepaidV2FeatureSwitches();
    }, []);

    const canAccessFeature = (featureSwitch) => {
      return featureSwitches.includes(featureSwitch);
    }

    const canCreatePrepaidV2SellingPlans = () => {
      return canAccessFeature(FEATURE_SWITCHES.PREPAIDV2_SELLING_PLAN_GROUPS);
    }

    return (
        <FeaturesContext.Provider value={{ featureSwitches, canAccessFeature, canCreatePrepaidV2SellingPlans }}>
            {children}
        </FeaturesContext.Provider>
    );
};

export const usePrepaidFeatures = () => useContext(FeaturesContext);
